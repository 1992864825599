import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    ItemInterface,
    Estado,
    ServiciosConPlan,
} from "./ServiciosAdicionalesPagosAdmin";
import { useAppSelector } from "../../../hooks/hooks";

interface AccordionProps {
    handleEnable: (n: number) => void;
    setModalServiciosAdicionalesConPlan: (n: boolean) => void;
    serviciosConPlan: ServiciosConPlan[];
    eliminarPlan: (n: number) => void;
    setIdPlan: (n: number) => void;
}

const Accordion: React.FC<AccordionProps> = ({
    handleEnable,
    serviciosConPlan,
    eliminarPlan,
    setIdPlan,
    setModalServiciosAdicionalesConPlan,
}) => {
    const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
    const navigate = useNavigate();
    const handleAccordionClick = (index: number) => {
        setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
    };

    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);

    const handleDetalle = (item: ItemInterface) => {
        if (tipo_usuario === 3 || tipo_usuario === 4) {
            navigate(`/dashboard/crm/${item.id}`);
        }

        if (tipo_usuario === 6) {
            navigate(`/dashboard-corredor/crm/${item.id}`);
        }
    };
    console.log("serviciosConPlan", serviciosConPlan);
    return (
        <div className="flex flex-col w-11/12 mx-auto mt-6 max-w-7xl">
            {serviciosConPlan.map((servicio) => {
                return (
                    <div key={servicio.id} className="w-full my-1">
                        <div
                            className={`2xl:text-lg text-base font-bold cursor-pointer p-2 rounded-md border-2 flex items-center relative  ${
                                activeAccordion === servicio.id
                                    ? "bg-slate-200 hover:bg-gray-100"
                                    : "bg-gray-100 hover:bg-gray-50"
                            }`}
                            onClick={() => handleAccordionClick(servicio.id)}>
                            <div className="flex items-center gap-1">
                                {activeAccordion !== servicio.id ? (
                                    <svg
                                        className="w-5 h-5 text-black"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path
                                            d="M18 15l-6-6l-6 6h12"
                                            transform="rotate(90 12 12)"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="w-5 h-5 text-black"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path
                                            d="M18 15l-6-6l-6 6h12"
                                            transform="rotate(180 12 12)"
                                        />
                                    </svg>
                                )}
                                {servicio.tipo_plan} (
                                {servicio.servicios_adicionales.length})
                            </div>
                            <div className="flex items-center gap-2">
                                <button
                                    onClick={() => (
                                        setModalServiciosAdicionalesConPlan(
                                            true
                                        ),
                                        setIdPlan(servicio.id)
                                    )}
                                    className={` absolute  dark:text-gray-400 top-2 right-10 ${"bg-blue-300 text-gray-800"}  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 disabled:cursor-not-allowed z-50`}>
                                    <svg
                                        className="rounded-md hover:bg-gray-600 hover:text-white"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                {!servicio.servicios_adicionales.length && (
                                    <button
                                        onClick={() =>
                                            eliminarPlan(servicio.id)
                                        }
                                        className={` absolute  dark:text-gray-400 top-2 right-2 ${"bg-red-300 text-slate-800"}  rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 disabled:cursor-not-allowed z-50`}>
                                        <svg
                                            className="rounded-full hover:bg-gray-600 hover:text-white"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18 6L6 18"
                                                stroke="currentColor"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M6 6L18 18"
                                                stroke="currentColor"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </div>
                        {activeAccordion === servicio.id ? (
                            <div className="flex flex-wrap mt-3 justify-evenly">
                                {servicio.servicios_adicionales.map(
                                    (servicio) => {
                                        return (
                                            <div
                                                key={servicio.id}
                                                className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345] pb-6   w-[330px] mb-4 relative ">
                                                <button
                                                    onClick={() =>
                                                        handleEnable(
                                                            servicio.id
                                                        )
                                                    }
                                                    className={` absolute  dark:text-gray-400 top-2 right-2 ${"bg-slate-300 text-gray-800"}  rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 disabled:cursor-not-allowed z-50`}>
                                                    <svg
                                                        className="rounded-full hover:bg-gray-600 hover:text-white"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M18 6L6 18"
                                                            stroke="currentColor"
                                                            strokeWidth="1.66667"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M6 6L18 18"
                                                            stroke="currentColor"
                                                            strokeWidth="1.66667"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                                <div className="flex flex-col gap-2 md:gap-0">
                                                    <div className="flex flex-col ">
                                                        <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                                            {
                                                                <div className="flex">
                                                                    <p className="text-base font-extrabold">
                                                                        $
                                                                    </p>
                                                                    <p className="text-5xl text-[#179BD8] font-bold">
                                                                        {" "}
                                                                        {
                                                                            servicio.precio
                                                                        }
                                                                    </p>
                                                                </div>
                                                            }

                                                            <div className="text-base font-semibold xl:text-lg">
                                                                /
                                                                {
                                                                    servicio.tipo_pago
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col mt-6">
                                                        <p className="font-bold">
                                                            {servicio.titulo}
                                                        </p>
                                                        <p className="mt-3">
                                                            {
                                                                servicio.descripcion
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};

export default Accordion;
