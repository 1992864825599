import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import { ButtonSiguiente } from "./ButtonSiguiente";
import capacidad from "../../img/onboarding/capacidad.jpg";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import "../../assets/css/styles_input_number.css";
import BotonInformacion from "../../components/helpers/BotonInformacion";
export const Capacidad = () => {
    const { idCasa } = useParams();
    const {
        id: id_casa,
        banos,
        habitaciones,
        personas,
        mascotas,
    } = useAppSelector((state) => state.landing_house.house);

    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );

    const [input, setInput] = useState({
        personas: 0,
        banos: 0,
        habitaciones: 0,
        mascotas: 0,
    });
    const navigate = useNavigate();

    useEffect(() => {
        setInput({
            personas: personas,
            banos: Number(banos),
            habitaciones: Number(habitaciones),
            mascotas: mascotas,
        });
    }, [personas, banos, mascotas, habitaciones]);

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]:
                e.target.value === "" ? null : Number(e.target.value),
        });
    };

    const handleSubmit = async () => {
        if (input.personas < 1)
            return Swal.fire({
                title: "Debes escoger un numero de personas válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
            });
        else if (input.mascotas < 0)
            return Swal.fire({
                title: "Debes escoger un numero de mascotas válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
            });
        else if (input.banos < 0.5)
            return Swal.fire({
                title: "Debes escoger un numero de baños válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
            });
        else if (input.habitaciones < 1)
            return Swal.fire({
                title: "Debes escoger un numero de habitaciones válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
            });
        else
            try {
                if (idCasa && Number(idCasa) > 0) {
                    await fetchAxiosToken({
                        url: `/casa/putPersonasMascotas/${idCasa}`,
                        method: "put",
                        body: input,
                    });

                    await fetchAxiosToken({
                        url: `/casa/putPagina/${idCasa}`,
                        method: "put",
                        body: {
                            pagina: "tema",
                        },
                    });
                    dispatchDominio();
                    navigate(`/dashboard-corredor/onboarding/tema/${idCasa}`);
                } else {
                    await fetchAxiosToken({
                        url: `/casa/putPersonasMascotas/${id_casa}`,
                        method: "put",
                        body: input,
                    });

                    await fetchAxiosToken({
                        url: `/casa/putPagina/${id_casa}`,
                        method: "put",
                        body: {
                            pagina: "tema",
                        },
                    });
                    dispatchDominio();
                    navigate(`/dashboard/onboarding/tema`);
                }
            } catch (error) {
                console.log(error);
            }
    };

    const handleCapacidad = (currentInput: string, type: string) => {
        if (currentInput === "personas") {
            if (type === "suma") {
                setInput({
                    ...input,
                    personas: input.personas + 1,
                });
            }
            if (type === "resta" && Number(input.personas) > 0) {
                setInput({
                    ...input,
                    personas: input.personas - 1,
                });
            }
        }

        if (currentInput === "mascotas") {
            if (type === "suma") {
                setInput({
                    ...input,
                    mascotas: input.mascotas + 1,
                });
            }
            if (type === "resta" && Number(input.mascotas) > 0) {
                setInput({
                    ...input,
                    mascotas: input.mascotas - 1,
                });
            }
        }

        if (currentInput === "banos") {
            if (type === "suma") {
                setInput({
                    ...input,
                    banos: input.banos + 0.5,
                });
            }
            if (type === "resta" && Number(input.banos) > 0) {
                setInput({
                    ...input,
                    banos: input.banos - 0.5,
                });
            }
        }

        if (currentInput === "habitaciones") {
            if (type === "suma") {
                setInput({
                    ...input,
                    habitaciones: input.habitaciones + 1,
                });
            }
            if (type === "resta" && Number(input.habitaciones) > 0) {
                setInput({
                    ...input,
                    habitaciones: input.habitaciones - 1,
                });
            }
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/3 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={capacidad}
                        alt="capacidad"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Especifica las capacidades de tu alojamiento
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center h-screen gap-2 p-3 text-center text-black bg-white">
                <ButtonRegresar
                    url={`${
                        idCasa
                            ? `/dashboard-corredor/onboarding/suscripcion/${idCasa}`
                            : "/dashboard/onboarding/alojamiento"
                    }`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col items-start justify-start w-3/4 mx-auto mt-20 md:w-2/3 lg:w-1/2 2xl:mt-20 ">
                    <div className="flex flex-col items-start justify-start max-w-md mx-auto">
                        <div className="flex items-center gap-2 mt-3 2xl:mt-5">
                            <label className="text-base font-medium lg:text-lg 2xl:text-2xl">
                                Número de personas
                            </label>
                            <BotonInformacion
                                text={
                                    "Coloca el número máximo de personas que caben en tu propiedad (desde 2 años en adelante)"
                                }
                                ver={true}
                                left={14}
                                widthHeight={" w-4 h-4"}
                            />
                        </div>
                        <div className="flex items-center justify-center w-full gap-5 mt-2">
                            <input
                                type={"number"}
                                min={0}
                                name="personas"
                                value={input.personas}
                                onChange={handleInputChange}
                                // placeholder="nombra tu alojamiento"
                                className="w-full  mt-2 mb-2 text-black border border-gray-500 rounded-lg md:mb-1 2xl:p-3 lg:p-2 p-1.5 placeholder:italic"
                            />
                            <button
                                onClick={() =>
                                    handleCapacidad("personas", "resta")
                                }
                                className="flex items-center justify-center p-2 text-2xl text-black bg-gray-300 rounded-lg cursor-pointer 2xl:p-4 lg:p-3 lg:text-3xl hover:bg-gray-400"
                            >
                                <svg
                                    className="w-4 h-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </button>

                            <button
                                onClick={() =>
                                    handleCapacidad("personas", "suma")
                                }
                                className="flex items-center justify-center p-2 text-2xl text-black bg-gray-300 rounded-lg cursor-pointer 2xl:p-4 lg:p-3 lg:text-3xl hover:bg-gray-400"
                            >
                                <svg
                                    className="w-4 h-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start max-w-md mx-auto">
                        <div className="flex items-center gap-2 mt-3 2xl:mt-5">
                            <label className="text-base font-medium lg:text-lg 2xl:text-2xl">
                                Número de mascotas
                            </label>
                            <BotonInformacion
                                text={
                                    "Coloca el número máximo de mascotas que caben en tu propiedad"
                                }
                                ver={true}
                                left={14}
                                widthHeight={" w-4 h-4"}
                            />
                        </div>

                        <div className="flex items-center justify-center w-full gap-5 mt-2">
                            <input
                                type={"number"}
                                min={0}
                                name="mascotas"
                                value={input.mascotas}
                                onChange={handleInputChange}
                                // placeholder="nombra tu alojamiento"
                                className="w-full mt-2 mb-2 text-black border border-gray-500 rounded-lg md:mb-1 2xl:p-3 lg:p-2 p-1.5 placeholder:italic"
                            />
                            <button
                                onClick={() =>
                                    handleCapacidad("mascotas", "resta")
                                }
                                className="flex items-center justify-center p-2 text-2xl text-black bg-gray-300 rounded-lg cursor-pointer 2xl:p-4 lg:p-3 lg:text-3xl hover:bg-gray-400"
                            >
                                <svg
                                    className="w-4 h-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </button>

                            <button
                                onClick={() =>
                                    handleCapacidad("mascotas", "suma")
                                }
                                className="flex items-center justify-center p-2 text-2xl text-black bg-gray-300 rounded-lg cursor-pointer 2xl:p-4 lg:p-3 lg:text-3xl hover:bg-gray-400"
                            >
                                <svg
                                    className="w-4 h-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start max-w-md mx-auto">
                        <div className="flex items-center gap-2 mt-3 2xl:mt-5">
                            <label className="text-base font-medium lg:text-lg 2xl:text-2xl">
                                Número de habitaciones
                            </label>
                            <BotonInformacion
                                text={
                                    "Coloca el número total de habitaciones , incluidas las de servicio"
                                }
                                ver={true}
                                left={14}
                                widthHeight={" w-4 h-4"}
                            />
                        </div>

                        <div className="flex items-center justify-center w-full gap-5 mt-2">
                            <input
                                type={"number"}
                                min={0}
                                step={1}
                                name="habitaciones"
                                value={input.habitaciones}
                                onChange={handleInputChange}
                                className="w-full mt-2 mb-2 text-black border border-gray-500 rounded-lg md:mb-1 2xl:p-3 lg:p-2 p-1.5 placeholder:italic"
                            />

                            <button
                                onClick={() =>
                                    handleCapacidad("habitaciones", "resta")
                                }
                                className="flex items-center justify-center p-2 text-2xl text-black bg-gray-300 rounded-lg cursor-pointer 2xl:p-4 lg:p-3 lg:text-3xl hover:bg-gray-400"
                            >
                                <svg
                                    className="w-4 h-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </button>

                            <button
                                onClick={() =>
                                    handleCapacidad("habitaciones", "suma")
                                }
                                className="flex items-center justify-center p-2 text-2xl text-black bg-gray-300 rounded-lg cursor-pointer 2xl:p-4 lg:p-3 lg:text-3xl hover:bg-gray-400"
                            >
                                <svg
                                    className="w-4 h-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start max-w-md mx-auto">
                        <div className="flex items-center gap-2 mt-3 2xl:mt-5">
                            <label className="text-base font-medium lg:text-lg 2xl:text-2xl">
                                {" "}
                                Número de baños
                            </label>
                            <BotonInformacion
                                text={
                                    "Coloca el número total de baños y medios baños"
                                }
                                ver={true}
                                left={14}
                                widthHeight={" w-4 h-4"}
                            />
                        </div>

                        <div className="flex items-center justify-center w-full gap-5 mt-2">
                            <input
                                type={"number"}
                                min={0}
                                step={0.5}
                                name="banos"
                                value={input.banos}
                                onChange={handleInputChange}
                                // placeholder="nombra tu alojamiento"
                                className="w-full mt-2 mb-2 text-black border border-gray-500 rounded-lg md:mb-1 2xl:p-3 lg:p-2 p-1.5 placeholder:italic"
                            />

                            <button
                                onClick={() =>
                                    handleCapacidad("banos", "resta")
                                }
                                className="flex items-center justify-center p-2 text-2xl text-black bg-gray-300 rounded-lg cursor-pointer 2xl:p-4 lg:p-3 lg:text-3xl hover:bg-gray-400"
                            >
                                <svg
                                    className="w-4 h-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </button>

                            <button
                                onClick={() => handleCapacidad("banos", "suma")}
                                className="flex items-center justify-center p-2 text-2xl text-black bg-gray-300 rounded-lg cursor-pointer 2xl:p-4 lg:p-3 lg:text-3xl hover:bg-gray-400"
                            >
                                <svg
                                    className="w-4 h-4 text-black"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                            </button>
                        </div>
                        <ButtonSiguiente onSubmit={() => handleSubmit()} />
                    </div>
                </div>
            </div>
        </div>
    );
};
