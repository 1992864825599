import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import BotonInformacion from "../../components/helpers/BotonInformacion";
import Breadcrumbs from "../../components/helpers/Breadcrumbs";
import ToggleButton from "../../components/helpers/ToggleButton";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { logOut, verifyToken } from "../../redux/reducers/authReducer";
import {
    mostrar_informacion,
    ocultar_informacion,
} from "../../redux/reducers/InformacionReducer";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { Notificaiones } from "../../designs/modalEdit/Notificaiones";

const TITLES = [
    {
        id: 1,
        title: "Calendario",
        url: "calendario",
        textInfo: "Aquí puedes visualizar tu calendario de reservas y precios.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 99.99">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m50.06,35.65h48.35c1.58,0,1.59,0,1.59,1.59,0,17.23,0,34.46,0,51.69,0,6.41-4.61,11.05-11,11.05-25.99,0-51.99,0-77.99,0C4.67,99.98.01,95.35.01,89.04,0,71.73,0,54.43,0,37.12c0-1.46,0-1.47,1.48-1.47h48.57m-25.01,17.99c-1.11,0-2.23-.03-3.34,0-2.16.07-3.73,1.58-3.71,3.51.03,1.85,1.62,3.39,3.68,3.44,2.22.05,4.45.05,6.67,0,2.1-.04,3.75-1.64,3.71-3.51-.03-1.85-1.63-3.37-3.68-3.44-1.11-.04-2.23,0-3.34,0m24.9,6.97c1.11,0,2.23.03,3.34,0,2.11-.06,3.75-1.61,3.74-3.49-.01-1.88-1.65-3.43-3.76-3.46-2.19-.04-4.38-.04-6.57,0-2.17.04-3.74,1.54-3.73,3.48,0,1.88,1.64,3.41,3.76,3.47,1.07.03,2.15,0,3.23,0m25.11-6.97c-1.15,0-2.3-.03-3.45,0-2.16.07-3.73,1.6-3.69,3.52.04,1.86,1.63,3.38,3.69,3.43,2.22.05,4.45.05,6.67,0,2.1-.04,3.74-1.66,3.7-3.53-.05-1.86-1.64-3.36-3.69-3.42-1.07-.04-2.15,0-3.23,0m-50.03,21.39c-1.11,0-2.23-.03-3.34,0-2.15.07-3.73,1.59-3.7,3.52.04,1.86,1.63,3.39,3.69,3.43,2.22.05,4.45.05,6.67,0,2.1-.04,3.75-1.65,3.7-3.52-.04-1.85-1.64-3.36-3.69-3.43-1.11-.03-2.22,0-3.34,0m25.01,6.97c1.07,0,2.15.03,3.23,0,2.11-.06,3.75-1.59,3.75-3.48,0-1.88-1.64-3.44-3.75-3.48-2.19-.04-4.38-.04-6.57,0-2.18.04-3.74,1.52-3.75,3.47,0,1.88,1.63,3.43,3.74,3.49,1.11.03,2.23,0,3.34,0m24.86,0c1.11,0,2.23.03,3.34,0,2.11-.06,3.75-1.61,3.74-3.49-.01-1.89-1.65-3.43-3.77-3.46-2.19-.04-4.38-.04-6.57,0-2.17.04-3.74,1.54-3.73,3.48,0,1.89,1.64,3.41,3.76,3.47,1.07.03,2.15,0,3.23,0"
                        />
                        <path
                            fill="white"
                            d="m49.92,28.68H1.79Q0,28.68,0,26.84C0,23.76-.01,20.68.02,17.6.06,11.93,4.81,7.18,10.48,7.14c4.38-.03,8.76-.04,13.15.01,1.1.01,1.46-.34,1.35-1.4-.07-.7-.02-1.41,0-2.12C25.02,1.48,26.52-.01,28.64,0c2.04.01,3.56,1.52,3.61,3.59.02.85.04,1.71,0,2.56-.03.72.24,1.01.97.98,1.11-.04,2.23,0,3.34,0h29.74q1.45,0,1.45-1.48c0-.63,0-1.26,0-1.89C67.79,1.5,69.26,0,71.42,0c2.12,0,3.59,1.56,3.62,3.81,0,.7.05,1.41,0,2.11-.08.92.26,1.23,1.19,1.22,4.23-.04,8.46-.02,12.7-.02,6.47.01,11.06,4.61,11.08,11.09,0,2.93,0,5.86,0,8.8q0,1.66-1.63,1.66h-48.45"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 2,
        title: "Reservas",
        url: "reservas",
        textInfo:
            "Aquí puedes ver los pedidos de reservas que te haran los clientes.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.54 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m49.75,73.36c-13.18,0-26.36,0-39.54,0-2.33,0-3.81-1.47-3.7-3.65.07-1.4.92-2.61,2.33-2.98.88-.23,1-.68,1.02-1.46.13-4.61.59-9.18,1.81-13.66,2.56-9.41,8.55-15.56,17.67-18.73,4.8-1.67,9.77-2.49,14.84-2.72,2.23-.1,2.67-.68,2.18-2.91-.13-.59-.6-.49-.95-.62-1.55-.58-2.41-1.8-2.36-3.42.04-1.46.94-2.68,2.33-3.1.36-.11.75-.15,1.12-.15,2.18-.01,4.36-.02,6.54,0,1.77.02,3.13,1.14,3.4,2.76.3,1.81-.65,3.41-2.46,3.91-.71.19-.83.53-.84,1.16-.01,2.3-.03,2.24,2.32,2.37,6.05.32,11.94,1.35,17.52,3.84,8.03,3.58,12.86,9.79,15.02,18.23,1.09,4.28,1.53,8.65,1.64,13.06.02.8.2,1.2,1.04,1.44,1.67.47,2.56,2.08,2.29,3.82-.25,1.63-1.67,2.79-3.48,2.79-9.72,0-19.44,0-29.16,0-3.53,0-7.06,0-10.59,0Zm6.41-29.71c-1.32-.17-2.31.99-2.74,2.1-.84,2.14.65,4.19,3.11,4.39,2.28.18,4.53.52,6.71,1.24,2.81.94,4.81,2.67,5.55,5.65.11.43.28.86.5,1.25.79,1.35,2.4,1.9,3.86,1.36,1.42-.53,2.34-2.12,1.99-3.59-1.04-4.33-3.3-7.73-7.41-9.73-3.36-1.64-6.95-2.23-11.58-2.66Z"
                        />
                        <path
                            fill="white"
                            d="m49.65,99.99c-15.95,0-31.9,0-47.84,0-.31,0-.62-.02-.93,0-.84.08-1.02-.26-.76-1.04.94-2.75,1.86-5.51,2.76-8.28.19-.57.51-.84,1.09-.93,3.27-.5,5.22-2.38,5.72-5.66.15-.98.78-.88,1.44-.88,11.21,0,22.42,0,33.63,0q1.62,0,1.62-1.64t1.69-1.69c1.38,0,2.77.03,4.15,0,.73-.02.94.28.96.98.06,2.36.08,2.36,2.45,2.36,10.9,0,21.79.01,32.69-.02,1.02,0,1.45.26,1.6,1.32.39,2.68,2.54,4.77,5.25,5.16.92.13,1.36.53,1.63,1.41.78,2.55,1.66,5.07,2.5,7.6.43,1.3.42,1.31-.97,1.31-16.22,0-32.45,0-48.67,0Z"
                        />
                        <path
                            fill="white"
                            d="m46.37,6.67c0-1.14-.03-2.29,0-3.43C46.43,1.36,47.86,0,49.73,0c1.87,0,3.37,1.35,3.4,3.19.05,2.35.05,4.71,0,7.07-.04,1.85-1.53,3.2-3.4,3.19-1.83,0-3.3-1.42-3.36-3.25-.03-1.18,0-2.36,0-3.53Z"
                        />
                        <path
                            fill="white"
                            d="m19.93,16.77c-.04,1.36-.63,2.41-1.86,2.99-1.25.6-2.54.54-3.61-.36-1.43-1.2-2.76-2.52-3.95-3.96-1.07-1.31-.9-3.29.25-4.47,1.18-1.21,3.2-1.4,4.54-.3,1.43,1.19,2.73,2.55,3.96,3.95.51.59.72,1.33.67,2.13Z"
                        />
                        <path
                            fill="white"
                            d="m79.57,16.21c-.01-.5.36-1.3,1.01-1.97,1.06-1.09,2.12-2.18,3.23-3.23,1.47-1.38,3.63-1.38,4.94-.03,1.28,1.32,1.29,3.44-.06,4.88-1.09,1.16-2.21,2.29-3.38,3.37-1.13,1.03-2.48,1.2-3.86.55-1.28-.6-1.87-1.69-1.88-3.56Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 3,
        title: "Chat",
        url: "chat",
        textInfo:
            "Aquí puedes ver los mensajes que te envien tus clientes y podras conversar con ellos.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 95.03">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m100,19.8v32.79c-.11.57-.22,1.15-.33,1.72-1.91,10.16-11.15,17.98-21.49,18.05-10.15.07-20.3.03-30.44,0-.92,0-1.58.26-2.23.92-6.81,6.85-13.65,13.67-20.48,20.5-1.22,1.21-2.43,1.54-3.69.98-1.39-.63-1.83-1.78-1.83-3.25.03-5.92.01-11.84.01-17.76q0-1.49-1.5-1.78C7.76,69.98.03,60.67,0,50.23c-.02-9.33,0-18.67,0-28,0-.91.06-1.81.17-2.72C1.59,8.55,11.17.04,22.24.02,40.75,0,59.25-.02,77.76.03c7.06.02,12.82,2.95,17.33,8.35,2.76,3.31,4.25,7.19,4.91,11.41ZM26.68,31.73c-3.19-.02-5.74,2.44-5.82,5.6-.07,3.12,2.59,5.81,5.75,5.81,3.12,0,5.71-2.55,5.77-5.7.06-3.09-2.55-5.7-5.7-5.72Zm23.43,0c-3.22-.03-5.86,2.54-5.83,5.68.02,3.12,2.62,5.72,5.74,5.73,3.12.01,5.71-2.53,5.79-5.68.07-3.08-2.53-5.71-5.69-5.73Zm23.42,0c-3.22-.02-5.86,2.54-5.83,5.69.02,3.13,2.62,5.72,5.74,5.73,3.12,0,5.71-2.54,5.78-5.69.07-3.08-2.54-5.71-5.69-5.73Z"
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 12,
        title: "Contactos",
        url: "contactos",
        textInfo: "Aquí puedes ver todo el detalle de tus contactos",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 62.39">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m53.4,27.32c1.41.22,2.72.32,4,.64,8.74,2.17,15.03,10.14,15.08,19.14.01,2.64.02,5.28,0,7.92-.04,4.06-3.3,7.34-7.36,7.35-10.06.03-20.12.02-30.18,0-4.06,0-7.38-3.29-7.35-7.34.02-3.68-.31-7.38.23-11.05,1.27-8.59,9.01-15.79,17.68-16.5.34-.03.68-.1,1.36-.2-3.87-1.13-6.71-3.13-8.63-6.34-1.89-3.18-2.51-6.57-1.65-10.19C38.1,4.3,43.7-.08,50.3,0c6.36.07,11.95,4.75,13.24,11.08,1.44,7.03-2.04,12.65-10.14,16.25Z"
                        />
                        <path
                            fill="white"
                            d="m17.38,24.84c-7.04-2.13-9.24-8.37-8.29-13.13,1.15-5.76,5.83-9.33,11.87-9.16,5.08.14,9.72,4.39,10.41,9.66.76,5.81-2.66,11.05-8.79,12.69,3.26,0,6.03.78,8.64,2.2,1.15.62,1.15.7.17,1.57-5.69,5.06-8.55,11.4-8.58,19.02,0,1.86,0,3.73,0,5.59q0,1.64-1.7,1.64c-4.35,0-8.7,0-13.05,0-4.27,0-7.38-2.52-7.8-6.74-.4-4.09-.53-8.26,1.13-12.17,3.01-7.11,8.5-10.69,15.99-11.17Z"
                        />
                        <path
                            fill="white"
                            d="m82.24,24.91c9.43.16,16.59,6.75,17.53,15.9.27,2.59.34,5.2-.03,7.78-.53,3.7-3.61,6.32-7.36,6.33-4.58.02-9.17-.03-13.75.03-1.16.01-1.43-.42-1.43-1.49-.01-3.37.27-6.76-.31-10.11-1.01-5.89-3.8-10.78-8.3-14.71-.92-.8-.91-.91.17-1.5,2.62-1.43,5.41-2.25,8.53-2.22-7.12-2.13-9.48-8.2-8.57-13.09,1.09-5.78,5.76-9.4,11.78-9.27,5.15.11,9.83,4.36,10.53,9.67.76,5.81-2.67,11.04-8.82,12.69Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 4,
        title: "Estadísticas",
        url: "estadisticas",
        textInfo: "Aquí puedes ver las estadísticas que te ofrece BLU.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 91.01">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m100,88.63c-.81,1.75-2.22,2.39-4.1,2.38-6.47-.04-12.94,0-19.41-.02-2.86,0-4.21-1.31-4.21-4.17-.01-20.52-.01-41.03,0-61.54,0-2.95,1.31-4.22,4.24-4.22,6.47,0,12.94.02,19.41-.02,1.89-.01,3.28.62,4.07,2.4v65.19Z"
                        />
                        <path
                            fill="white"
                            d="m63.94,45.52v41.17c0,3.05-1.26,4.31-4.34,4.31-6.44,0-12.88,0-19.32,0-1.55,0-2.9-.37-3.72-1.84-.42-.74-.43-1.55-.43-2.37,0-19.12,0-38.24,0-57.36,0-8.42,0-16.84,0-25.27C36.13,1.31,37.44,0,40.32,0,46.79,0,53.26,0,59.73,0c2.92,0,4.2,1.3,4.2,4.25v41.27"
                        />
                        <path
                            fill="white"
                            d="m0,66.52C0,59.69,0,52.86,0,46.03c0-2.63,1.25-3.9,3.88-3.9,6.66,0,13.33,0,20,0,2.37,0,3.62,1.12,3.87,3.47.04.42.04.85.04,1.27,0,13.14,0,26.28,0,39.42,0,.36,0,.72-.02,1.07-.18,2.36-1.48,3.59-3.84,3.64-.94.02-1.88,0-2.83,0H4.41c-.32,0-.65.01-.97-.01-2.18-.16-3.43-1.49-3.43-3.68,0-6.93,0-13.85,0-20.78"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 5,
        title: "Reseñas",
        url: "reviews",
        textInfo:
            "Aquí puedes ver las reseñas de los clientes que ya te hicieron una reserva.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.76 95.45">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m55.13,3.27l12.24,24.79,27.36,3.98c4.81.7,6.73,6.61,3.25,10l-19.8,19.3,4.67,27.25c.82,4.79-4.2,8.44-8.5,6.18l-24.47-12.87-24.47,12.87c-4.3,2.26-9.32-1.39-8.5-6.18l4.67-27.25L1.78,42.03c-3.48-3.39-1.56-9.3,3.25-10l27.36-3.98L44.62,3.27c2.15-4.36,8.36-4.36,10.51,0Z"
                    />
                </g>
            </svg>
        ),
    },
    {
        id: 6,
        title: "CRM",
        url: "crm",
        textInfo:
            "Aquí puedes ver los leads que te llegan, desde chats y pedidos.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 87.85">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m0,27.24c.32-.65.78-1.19,1.29-1.7,7.96-7.95,15.91-15.9,23.86-23.85,1.87-1.87,3.38-1.86,5.26.03,2.13,2.14,4.24,4.31,6.4,6.42.55.54.55.87,0,1.41-3.12,3.08-6.21,6.2-9.32,9.3-2.04,2.03-3.12,4.42-2.88,7.36.19,2.22,1.15,4.05,2.71,5.57,5.4,5.25,13.98,5.35,19.76.26,2.12-1.86,3.18-1.87,5.32-.02,5.02,4.33,11.26,4.98,17.11,1.75.56-.31.91-.26,1.35.18,5.69,5.71,11.41,11.4,17.09,17.11,1.89,1.9,1.95,4.57.23,6.39-1.78,1.88-4.43,1.97-6.44.07-2.36-2.23-4.62-4.57-6.92-6.87-.54-.54-1.12-.99-1.9-1.1-1.17-.17-2.3.4-2.86,1.44-.6,1.11-.46,2.35.49,3.32,2.32,2.36,4.68,4.69,7.02,7.05,1.2,1.21,1.77,2.65,1.39,4.35-.7,3.13-4.27,4.52-6.91,2.71-.43-.29-.81-.66-1.17-1.02-2.17-2.15-4.32-4.33-6.49-6.47-1.35-1.33-3.07-1.39-4.23-.19-1.14,1.18-1.04,2.83.27,4.16,2.24,2.26,4.51,4.5,6.75,6.77,1.85,1.88,2.07,4.35.61,6.17-1.76,2.18-4.65,2.36-6.78.36-1.19-1.11-2.34-2.26-3.45-3.45-1.92-2.06-4.23-3.33-7.06-3.51-.76-.05-.95-.37-1.02-1.08-.43-4.2-2.59-7.13-6.56-8.64-.72-.27-.65-.61-.43-1.18,1.73-4.46.69-8.79-2.98-11.76-2.97-2.4-6.36-2.81-9.91-1.47-.85.32-1.2.28-1.54-.65-2.44-6.47-10.97-8.42-16.09-3.72-1.56,1.43-1.59,1.44-2.23-.61-.9-2.89-2.43-5.37-4.6-7.47-1.24-1.2-2.42-2.45-3.64-3.66-.56-.55-1.07-1.14-1.46-1.82v-1.95Z"
                        />
                        <path
                            fill="white"
                            d="m55.02,8.7c1.46,0,2.93-.03,4.39,0,1.87.05,3.4-.6,4.7-1.95,1.69-1.76,3.44-3.46,5.17-5.18,2.11-2.1,3.57-2.11,5.65-.03,7.86,7.86,15.73,15.72,23.59,23.59,1.99,1.99,1.99,3.5,0,5.51-1.99,2.01-4,4-6,6-1.62,1.62-2.19,3.55-1.78,5.79.14.8.28,1.59.1,2.42-.16.74-.43.7-.87.24-.18-.19-.37-.37-.55-.55-6.02-6.02-12.06-12.04-18.06-18.08-.67-.67-1.05-.75-1.79-.06-3.54,3.3-8.91,3.75-13.22,1.18-.78-.47-1.55-.96-2.34-1.41-3.16-1.8-7.09-1.45-9.83.94-1.8,1.57-3.72,2.85-6.19,3-2.79.17-5.19-.76-7.09-2.79-1.08-1.16-.96-2.84.2-4.05,1.33-1.38,2.7-2.72,4.06-4.08,2.39-2.39,4.84-4.73,7.16-7.19,2.18-2.31,4.81-3.33,7.93-3.31,1.59.01,3.19,0,4.78,0Z"
                        />
                        <path
                            fill="white"
                            d="m37.7,56.36c-.03,1.4-.53,2.47-1.41,3.36-3.37,3.39-6.74,6.77-10.13,10.13-1.36,1.34-2.99,1.82-4.83,1.15-1.78-.66-2.76-2.01-2.94-3.89-.13-1.33.35-2.51,1.28-3.45,3.43-3.46,6.86-6.92,10.34-10.33,1.43-1.4,3.18-1.78,5.03-.93,1.72.79,2.56,2.22,2.66,3.97Z"
                        />
                        <path
                            fill="white"
                            d="m12.22,60.93c-1.51-.08-2.92-.89-3.73-2.56-.82-1.68-.61-3.34.59-4.74,1.18-1.38,2.52-2.62,3.8-3.91.91-.92,1.81-1.87,2.78-2.74,1.34-1.19,2.89-1.59,4.61-.94,1.7.65,2.67,1.92,2.91,3.71.18,1.33-.23,2.55-1.15,3.5-2.12,2.2-4.3,4.34-6.48,6.48-.82.81-1.86,1.17-3.33,1.2Z"
                        />
                        <path
                            fill="white"
                            d="m33.25,81.65c-1.82-.13-3.23-.88-4.04-2.52-.81-1.63-.73-3.29.45-4.66,2.16-2.51,4.52-4.85,7.01-7.03,1.74-1.53,4.34-1.27,5.93.36,1.58,1.61,1.82,4.13.32,5.86-2.17,2.5-4.57,4.79-7.01,7.04-.74.68-1.72.88-2.67.96Z"
                        />
                        <path
                            fill="white"
                            d="m43.25,83.28c0-.1,0-.19,0-.29.13-2.99,4.64-6.68,7.42-6.09,3.17.68,4.63,4.3,2.74,6.94-.87,1.22-1.95,2.28-3.16,3.16-1.44,1.05-3.03,1.09-4.61.32-1.56-.76-2.41-2.27-2.39-4.04Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 7,
        title: "Sub usuarios",
        url: "sub-usuarios",
        textInfo:
            "Aquí puedes ver los usuarios que te ayudaran a administrar tu casa.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 94.81 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m35.89,98.76c-6.15,0-12.31.02-18.46,0-2.73-.01-5.4-.48-7.94-1.5-4.81-1.94-7.8-5.55-8.77-10.56-1.91-9.78-.06-18.79,6.57-26.44,4.12-4.76,9.64-7.1,15.79-8.02,3.5-.53,7.02-.44,10.54-.45,5.75,0,11.5-.07,17.24,0,7.73.08,14.35,2.88,19.79,8.39,1.7,1.72,1.42,2.9-.84,3.74-9.64,3.6-15.78,13.05-15.07,23.29.2,2.89.92,5.66,2.17,8.29,1.08,2.28.43,3.28-2.08,3.28-6.31,0-12.63,0-18.94,0h0Z"
                        />
                        <path
                            fill="white"
                            d="m38.97,41.69c-11.43,0-20.84-9.4-20.82-20.77C18.18,9.43,27.58,0,39.01,0c11.5,0,21,9.34,20.92,20.83-.09,12.83-10.59,21.11-20.95,20.86Z"
                        />
                        <path
                            fill="white"
                            d="m72.82,81.64c1.05-.16,2.55.53,3.36-.31.75-.78.2-2.24.25-3.4.04-.93-.02-1.86.03-2.79.11-2.09,1.73-3.71,3.83-3.86,1.88-.14,3.69,1.28,4.1,3.26.13.63.11,1.28.17,1.93.15,1.67-.74,3.84.4,4.89,1.06.97,3.18.2,4.83.28.24.01.49,0,.73,0,2.53.04,4.28,1.66,4.3,3.97.02,2.34-1.65,4.01-4.17,4.12-1.29.05-2.59.01-3.89.02q-2.2,0-2.2,2.14c0,1.3.03,2.59-.01,3.89-.09,2.59-1.78,4.28-4.18,4.23-2.29-.05-3.88-1.82-3.91-4.37-.01-1.42,0-2.83,0-4.25q0-1.63-1.59-1.64c-1.54,0-3.08.04-4.61-.02-1.71-.07-3.05-.84-3.78-2.42-.67-1.45-.44-2.85.51-4.11.76-1,1.8-1.49,3.04-1.54.89-.04,1.78,0,2.82,0Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },

    {
        id: 9,
        title: "Medios de pago",
        // url: "medios-de-pago",
        textInfo:
            "Aquí puedes configurar los diferentes medios de pago que te ofrece BLU.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 99.97">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m50.01,0c27.44-.18,49.95,22.47,49.99,49.84.04,27.56-22.48,50.32-50.37,50.12C22.42,99.78.09,77.54,0,50.14-.09,22.41,22.57-.15,50.01,0Zm-4.85,41.39c0-2.29,1.48-4.26,3.65-4.87,2.09-.59,4.24.28,5.47,2.22.19.29.34.6.52.9.97,1.54,2.78,2.03,4.34,1.17,1.5-.82,2.04-2.59,1.27-4.22-1.27-2.68-3.23-4.66-6.02-5.72-.91-.34-1.24-.83-1.15-1.76.07-.79.03-1.59,0-2.39-.06-2.07-1.37-3.49-3.22-3.5-1.89-.02-3.22,1.41-3.28,3.55-.02.83-.05,1.67,0,2.49.06.8-.27,1.15-1,1.42-7.48,2.84-9.69,12.52-4.26,18.4,2.44,2.64,5.47,3.74,8.97,3.95,2.18.13,3.88,1.68,4.4,3.81.54,2.17-.28,4.33-2.12,5.54-2.55,1.68-5.78.74-7.3-2.13-1.02-1.92-2.88-2.61-4.58-1.69-1.65.89-2.12,2.78-1.14,4.69,1.28,2.5,3.22,4.34,5.84,5.36.95.37,1.24.88,1.2,1.84-.05,1.03-.14,2.08.11,3.1.38,1.54,1.62,2.56,3.13,2.55,1.51,0,2.72-1.02,3.11-2.57.21-.82.19-1.67.11-2.48-.15-1.38.29-2.1,1.66-2.7,5-2.21,7.71-7.74,6.46-12.74-1.3-5.19-5.65-8.68-11.33-9.1-2.79-.21-4.86-2.38-4.87-5.11Z"
                    />
                </g>
            </svg>
        ),
        children: [
            {
                id: "transferencia",
                title: "Transferencia",
                url: "transferencia",
                textInfo: "Configurar la cuenta para transferir.",
                icono: (
                    <svg
                        className="w-8 h-8 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                    </svg>
                ),
            },
            {
                id: "mercadopago",
                title: "Mercado Pago",
                url: "mercado-pago",
                textInfo: "Configurar la cuenta para transferir.",
                icono: (
                    <svg
                        className="w-8 h-8 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                    </svg>
                ),
            },
        ],
    },
    {
        id: 10,
        title: "Suscripciones",
        url: "suscripciones",
        textInfo: "Aqui puedes pagar tu suscripción a BLU.",
        icono: <p>BLU</p>,
    },
    // {
    //     id: 11,
    //     title: "servicio",
    //     url: "servicios",
    //     textInfo: "servicios adicionales",
    //     icono: (
    //         <svg
    //             className="w-5 h-5 2xl:w-6 2xl:h-6"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             stroke-width="2"
    //             stroke="currentColor"
    //             fill="none"
    //             strokeLinecap="round"
    //             strokeLinejoin="round">
    //             {" "}
    //             <path stroke="none" d="M0 0h24v24H0z" />{" "}
    //             <rect x="4" y="4" width="16" height="4" rx="1" />{" "}
    //             <rect x="4" y="12" width="6" height="8" rx="1" />{" "}
    //             <line x1="14" y1="12" x2="20" y2="12" />{" "}
    //             <line x1="14" y1="16" x2="20" y2="16" />{" "}
    //             <line x1="14" y1="20" x2="20" y2="20" />
    //         </svg>
    //     ),
    // },
    {
        id: 12,
        title: "Ayuda",
        url: "ayuda",
        textInfo: "Aca podras comunicarte con el equipo de soporte!",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 83.02 99.97">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m5.82,99.97c-1.12-.44-2.26-.84-3.17-1.67-2.29-2.08-3.11-4.62-2.41-7.66,3.81-16.64,17.64-29.6,34.48-32.31,1.34-.22,2.69-.32,4.05-.52-4.12-.74-7.81-2.36-10.96-5.11-4.07-3.55-6.26-8.07-6.78-13.4-.91-9.31,4.88-18.25,13.73-21.32,12.2-4.23,25.12,3.58,27.14,16.37.47,2.94.28,5.81-.53,8.67-.22.76-.61,1.02-1.39,1.02-4.03-.03-8.06-.03-12.09,0-1.66,0-2.76,1.07-2.82,2.61-.07,1.73,1.02,2.94,2.76,2.96,2.96.03,5.92,0,8.87.02.34,0,.8-.16,1,.18.22.39-.23.63-.44.88-3.16,3.8-7.18,6.12-12.04,7-.13.02-.25.05-.38.08-.02,0-.02.05-.07.15,1.92.14,3.8.39,5.66.8,12.42,2.72,21.59,9.83,27.95,20.71,2.19,3.76,3.81,7.77,4.55,12.07.62,3.67-1.55,7.04-5.08,8.19-.28.09-.55.19-.82.29H5.82Z"
                        />
                        <path
                            fill="white"
                            d="m79.03,37.44c0,2.08.01,4.16,0,6.24-.03,5.03-3.9,9.1-8.91,9.3-2.14.09-4.29.02-6.43.02-.24,0-.56.1-.7-.19-.13-.28.14-.47.28-.68,5.93-8.83,5.96-20.41.07-29.32-.15-.23-.48-.46-.33-.76.16-.3.53-.16.8-.16,1.27-.01,2.54,0,3.8-.01.31,0,.73.16.91-.13.2-.31-.14-.6-.3-.86-1.89-3.12-4.21-5.85-7.08-8.11-4.96-3.91-10.59-6.19-16.9-6.6-12.59-.8-22.45,3.99-29.35,14.65-.18.28-.59.6-.37.93.2.3.67.12,1.01.12,1.3.02,2.6.02,3.9,0,.68,0,.83.19.42.78-3.59,5.09-4.81,10.81-4.4,16.92.3,4.49,1.67,8.66,4.23,12.4.17.25.55.49.37.84-.17.34-.59.2-.89.19-2.5-.07-5.01.23-7.49-.18-4.27-.7-7.6-4.53-7.66-8.85-.05-4.35-.02-8.71,0-13.07,0-1.42.32-2.8,1.06-4.01.83-1.34,1.34-2.81,1.97-4.23C12.73,10.08,22.35,2.47,36.02.46c12.22-1.79,22.98,1.65,31.93,10.25,4,3.84,6.81,8.5,8.77,13.68.16.42.29.88.54,1.25,1.38,1.94,1.88,4.09,1.78,6.45-.07,1.78-.01,3.57-.01,5.36Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 13,
        title: "Configuraciones",
        url: "configuraciones",
        textInfo:
            "Aquí puedes configurar tu casa, revisa las diferentes opciones que te damos.",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 94.85">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m65.95,94.38c-2.01-.02-3.64-.85-4.85-2.42-2.49-3.26-5.79-5.08-9.84-5.27-4.44-.2-8.08,1.58-10.83,5.11-2.41,3.08-4.72,3.77-8.38,2.35-5.02-1.94-9.6-4.64-13.72-8.1-2.24-1.88-2.78-4.58-1.6-7.55,1.87-4.74,1.39-9.19-1.77-13.23-2.12-2.71-4.97-4.27-8.39-4.69-3.26-.4-5.37-2.26-5.92-5.48-.97-5.77-.85-11.54.36-17.26.56-2.67,2.75-4.53,5.46-4.86,6.65-.8,11.41-5.88,11.65-12.54.06-1.63-.28-3.24-.82-4.79-1.2-3.41-.44-5.85,2.42-8.05,3.85-2.96,8.04-5.29,12.56-7.03,3.11-1.19,5.69-.52,7.8,2.03,5.13,6.21,14.69,6.24,19.84.05C62.13,0,64.64-.63,67.89.63c5.01,1.95,9.61,4.61,13.75,8.05,2.39,1.99,3.09,4.43,2.06,7.45-1.44,4.23-.98,8.22,1.55,11.9,2.04,2.98,4.92,4.73,8.46,5.35,3,.53,4.87,2.23,5.46,5.21,1.15,5.86,1.08,11.72-.1,17.57-.52,2.57-2.57,4.39-5.34,4.86-5.67.96-9.64,4.57-10.91,9.92-.59,2.5-.4,4.95.48,7.36,1.29,3.55.63,5.87-2.34,8.25-3.75,3.01-7.86,5.44-12.3,7.28-.88.36-1.78.56-2.72.56Zm-32.48-46.87c.29,9.29,7.3,16.39,16.47,16.42,9.3.04,16.63-7.16,16.57-16.69-.06-9.3-7.31-16.38-16.47-16.37-9.45.02-16.41,7.33-16.57,16.63Z"
                    />
                </g>
            </svg>
        ),
    },
];

interface NavProps {
    children: React.ReactNode;
    titulo: string;
}

export const Navbar: React.FC<NavProps> = ({ children, titulo }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showChildren, setShowChildren] = useState<number>(-1);
    const { avatar, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const dispatch = useAppDispatch();
    const [ventana, setVentana] = useState(false);

    const navigate = useNavigate();
    const { mostrar, currency } = useAppSelector((state) => state.informacion);
    const { plan, nombre, dias_restantes, prueba } = useAppSelector(
        (state) => state.landing_house.house
    );
    const [toggleButton, setToggleButton] = useState(mostrar);
    const handleNavigate = (title: any) => {
        if (title.url) navigate(`/dashboard/${title.url}`);
        else {
            if (showChildren === title.id) setShowChildren(-1);
            else setShowChildren(title.id);
        }
    };

    const handleChildrenClick = (url: string) => {
        navigate(`/dashboard/${url}`);
    };

    useEffect(() => {
        dispatch(verifyToken()).then((data) => {
            dispatch(getHouse(data.payload.id));
        });
    }, []);

    useEffect(() => {
        if (toggleButton) {
            dispatch(mostrar_informacion());
        } else {
            dispatch(ocultar_informacion());
        }
    }, [toggleButton]);

    useLayoutEffect(() => {
        setTimeout(() => {
            setVentana(true);
        }, 5000);
    }, []);

    const ocultar = (titulo: string) => {
        const nombres = [
            "personas",
            "disponibilidad-maxima",
            "descuento-estadia",
            "cupones",
            "Chat",
            "CRM",
            "Estadisticas",
            "mercadopago",
            "Sub usuarios",
        ];

        if (nombres.includes(titulo) && plan < 2) {
            return "hidden";
        } else {
            return "";
        }
    };
    const opcionesPremium = (titulo: string) => {
        const nombres = [
            "personas",
            "disponibilidad-maxima",
            "descuento-estadia",
            "cupones",
            "Chat",
            "CRM",
            "Estadisticas",
            "mercadopago",
            "Sub usuarios",
        ];

        // if (nombres.includes(titulo)) {
        //     return true;
        // } else {
        //     return false;
        // }

        if (nombres.includes(titulo) && plan < 2) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className="flex w-full">
            {/* SIDE BAR */}
            <div
                className={`transition-all ease-out duration-700 fixed bottom-0 top-0 left-0 pl-1.5 bg-[#029BFC] text-white sm:flex  w-48 lg:w-64 hidden z-30 overflow-auto overflow-x-hidden `}>
                <div className="flex flex-col w-full gap-3 px-4 mt-7 ">
                    <a
                        className="hidden"
                        href={`https://wa.me/51950707924?text=`}
                        target="_blank"
                        rel="noreferrer">
                        <div className="fixed select-none bottom-5 right-5 xs:right-10 bg-[#00BB22] shadow-lg rounded-full p-1.5 pl-2 pb-2 flex justify-center items-center hover:scale-110 transition animate-bounce">
                            <div
                                className={`${
                                    ventana
                                        ? "opacity-70"
                                        : "opacity-0 invisible"
                                } absolute top-1.5 -left-[11.9rem] bg-blue-blueTo transition-all ease-in max-w-max text-gray-600 p-2 py-3 rounded-md shadow-lg cursor-pointer`}>
                                <span className=" h-0 w-0 absolute top-0 -right-5 z-40 border-b-[1.5rem] border-l-[1.5rem] border-t-[1.5rem] border-l-blue-blueTo border-t-transparent border-b-transparent"></span>
                                <p className="text-white">¿Podemos ayudarte?</p>
                            </div>
                            <svg
                                // onClick={() => {
                                //     setbuttonChatOpened(true);
                                //     setVentana(!ventana);
                                // }}
                                xmlns="http://www.w3.org/2000/svg"
                                className="z-20 w-12 h-12 cursor-pointer"
                                viewBox="0 0 175.216 175.552">
                                <defs>
                                    <linearGradient
                                        id="b"
                                        x1="85.915"
                                        x2="86.535"
                                        y1="32.567"
                                        y2="137.092"
                                        gradientUnits="userSpaceOnUse">
                                        <stop offset="0" stopColor="#57d163" />
                                        <stop offset="1" stopColor="#23b33a" />
                                    </linearGradient>
                                    <filter
                                        id="a"
                                        width="1.115"
                                        height="1.114"
                                        x="-.057"
                                        y="-.057"
                                        colorInterpolationFilters="sRGB">
                                        <feGaussianBlur stdDeviation="3.531" />
                                    </filter>
                                </defs>
                                <path
                                    fill="#b3b3b3"
                                    d="m54.532 138.45 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.523h.023c33.707 0 61.139-27.426 61.153-61.135.006-16.335-6.349-31.696-17.895-43.251A60.75 60.75 0 0 0 87.94 25.983c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.558zm-40.811 23.544L24.16 123.88c-6.438-11.154-9.825-23.808-9.821-36.772.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954zm0 0"
                                    filter="url(#a)"
                                />
                                <path
                                    fill="#fff"
                                    d="m12.966 161.238 10.439-38.114a73.42 73.42 0 0 1-9.821-36.772c.017-40.556 33.021-73.55 73.578-73.55 19.681.01 38.154 7.669 52.047 21.572s21.537 32.383 21.53 52.037c-.018 40.553-33.027 73.553-73.578 73.553h-.032c-12.313-.005-24.412-3.094-35.159-8.954z"
                                />
                                <path
                                    fill="url(#linearGradient1780)"
                                    d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.312-6.179 22.559 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.518 31.126 8.524h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.929z"
                                />
                                <path
                                    fill="url(#b)"
                                    d="M87.184 25.227c-33.733 0-61.166 27.423-61.178 61.13a60.98 60.98 0 0 0 9.349 32.535l1.455 2.313-6.179 22.558 23.146-6.069 2.235 1.324c9.387 5.571 20.15 8.517 31.126 8.523h.023c33.707 0 61.14-27.426 61.153-61.135a60.75 60.75 0 0 0-17.895-43.251 60.75 60.75 0 0 0-43.235-17.928z"
                                />
                                <path
                                    fill="#fff"
                                    fillRule="evenodd"
                                    d="M68.772 55.603c-1.378-3.061-2.828-3.123-4.137-3.176l-3.524-.043c-1.226 0-3.218.46-4.902 2.3s-6.435 6.287-6.435 15.332 6.588 17.785 7.506 19.013 12.718 20.381 31.405 27.75c15.529 6.124 18.689 4.906 22.061 4.6s10.877-4.447 12.408-8.74 1.532-7.971 1.073-8.74-1.685-1.226-3.525-2.146-10.877-5.367-12.562-5.981-2.91-.919-4.137.921-4.746 5.979-5.819 7.206-2.144 1.381-3.984.462-7.76-2.861-14.784-9.124c-5.465-4.873-9.154-10.891-10.228-12.73s-.114-2.835.808-3.751c.825-.824 1.838-2.147 2.759-3.22s1.224-1.84 1.836-3.065.307-2.301-.153-3.22-4.032-10.011-5.666-13.647"
                                />
                            </svg>
                        </div>
                    </a>
                    <p
                        onClick={() => navigate("/home")}
                        className="flex flex-col items-center justify-center gap-2 mb-4 text-xl font-bold cursor-pointer hover:scale-105">
                        <svg
                            className="w-10 h-10 2xl:w-12 2xl:h-12"
                            id="Capa_2"
                            data-name="Capa 2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 438 424.58">
                            <g id="Capa_1-2" data-name="Capa 1">
                                <path
                                    fill="white"
                                    d="m435.34,244.45L227.19,3.74c-4.32-4.99-12.06-4.99-16.38,0L2.66,244.45c-6.06,7.01-1.08,17.91,8.19,17.91h57.47l21.09,150.33c1.38,9.84,12.4,15.04,20.87,9.85l32.85-20.11c14-8.57,25.67-20.45,34-34.59h0c9.07-15.4,13.86-32.95,13.86-50.82v-62.55h-53.22c-2.85,0-4.19-5.01-2.05-7.7l81.24-102.22c1.17-1.47,2.93-1.47,4.11,0l81.24,102.22c2.13,2.69.8,7.7-2.05,7.7h-53.22v62.55c0,17.87,4.78,35.42,13.86,50.83h0c8.33,14.14,20,26.02,34,34.58l32.85,20.11c8.48,5.19,19.49-.01,20.87-9.85l21.09-150.33h57.47c9.27,0,14.25-10.89,8.19-17.91Z"
                                />
                            </g>
                        </svg>
                        <span className="text-sm text-center uppercase whitespace-wrap 2xl:text-base">
                            {nombre}
                        </span>
                    </p>

                    {/* <hr className="mt-1" /> */}
                    {TITLES.map((title) => (
                        <div
                            className={`transition ease-in cursor-pointer  ${
                                tipo_usuario === 4 &&
                                (title.id === 9 || title.id === 7)
                                    ? "hidden"
                                    : ""
                            }`}
                            key={title.id}>
                            <div className="flex flex-col cursor-pointer">
                                <button
                                    disabled={opcionesPremium(title.title)}
                                    title={
                                        opcionesPremium(title.title)
                                            ? "opcion estándar"
                                            : ""
                                    }>
                                    <div
                                        className={` flex items-center gap-2  ${
                                            opcionesPremium(title.title)
                                                ? " bg-slate-300 bg-opacity-20 mr-2 py-2 rounded-md pl-1 cursor-not-allowed"
                                                : " cursor-pointer hover:scale-105"
                                        }`}
                                        onClick={() => handleNavigate(title)}>
                                        <span>{title.icono}</span>
                                        <span className="text-base capitalize 2xl:text-lg ">
                                            {title.title}
                                        </span>

                                        <BotonInformacion
                                            text={title.textInfo}
                                            colorIcono="text-white"
                                        />
                                        {opcionesPremium(title.title) && (
                                            <span className="text-[10px] border border-yellow-300 text-yellow-300 p-[2px] rounded-md">
                                                Estándar
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            height:
                                                showChildren === title.id
                                                    ? `${
                                                          title.children &&
                                                          title.children
                                                              .length * 2.5
                                                      }rem`
                                                    : "0rem",
                                        }}
                                        className={`${
                                            showChildren === title.id
                                                ? `opacity-100 visible`
                                                : "opacity-0 invisible"
                                        } transition-all duration-300 ease-in-out mb-4 2xl:mb-5`}>
                                        {title.children &&
                                            title.children.map((children) => (
                                                <button
                                                    className={
                                                        currency === "EUR" &&
                                                        children.id ===
                                                            "mercadopago"
                                                            ? "hidden"
                                                            : ""
                                                    }
                                                    disabled={opcionesPremium(
                                                        children.id
                                                    )}
                                                    title={
                                                        opcionesPremium(
                                                            children.id
                                                        )
                                                            ? "opcion estándar"
                                                            : ""
                                                    }
                                                    onClick={() =>
                                                        handleChildrenClick(
                                                            children.url
                                                        )
                                                    }
                                                    key={children.id}>
                                                    <div
                                                        className={`  flex items-center p-2 pl-1 2xl:pl-10 ${
                                                            opcionesPremium(
                                                                children.id
                                                            )
                                                                ? "bg-slate-300 bg-opacity-20 mr-2 py-2 rounded-md pl-1 cursor-not-allowed"
                                                                : "hover:underline hover:scale-105 "
                                                        }`}>
                                                        <span className="mr-1">
                                                            {children.title}
                                                        </span>
                                                        <BotonInformacion
                                                            text={
                                                                children.textInfo
                                                            }
                                                            widthHeight={
                                                                "w-4 h-4"
                                                            }
                                                        />
                                                        {opcionesPremium(
                                                            children.id
                                                        ) && (
                                                            <span className="text-[10px] border border-yellow-300 text-yellow-300 p-[2px] rounded-md">
                                                                Estándar
                                                            </span>
                                                        )}
                                                    </div>
                                                </button>
                                            ))}
                                    </div>
                                </button>
                            </div>
                            <hr className="mr-2 " />
                        </div>
                    ))}
                </div>
            </div>
            {/* SIDE BAR */}
            {/* TOP BAR */}
            <div className="fixed top-0 left-0 z-20 flex-col justify-between w-full  bg-white  border-[#CCCCCC] mb-5 ">
                <div className="flex justify-between w-full p-4 border-b-2">
                    <div className="flex items-center pl-1 sm:pl-48 lg:pl-[265px] text-[#029BFC] font-bold text-lg md:text-xl 2xl:text-2xl">
                        <p>{titulo}</p>
                    </div>
                    <div
                        className="relative flex flex-col items-center"
                        onBlur={() => setShowOptions(false)}>
                        <div className="flex items-center gap-4 ">
                            <div
                                title="Mostrar informacion"
                                onClick={() => setToggleButton(!toggleButton)}
                                className={` hover:scale-105 cursor-help select-none 2xl:w-12 2xl:h-12 w-10 h-10 border-2 border-[#808080] rounded-full cursor-pointers flex justify-center items-center ${
                                    mostrar
                                        ? " text-white bg-[#808080]"
                                        : "text-[#808080] bg-white"
                                }`}>
                                <p className="text-2xl font-bold ">?</p>
                            </div>
                            <Notificaiones landing={false} />

                            <button onClick={() => setShowOptions(true)}>
                                <img
                                    src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${avatar}`}
                                    className="z-30 object-cover 2xl:w-12 2xl:h-12 w-10 h-10 border-2 border-[#808080] rounded-full cursor-pointer hover:scale-105"
                                    alt="foto de perfil"
                                />
                            </button>
                        </div>
                        <div
                            className={`${
                                showOptions
                                    ? "h-max opacity-100 visible"
                                    : "h-0 opacity-0 invisible"
                            }  fixed flex flex-col z-0  top-16 pt-4 bg-white transition-all duration-500 delay-200 ease-out right-4`}>
                            <div className="flex flex-col p-3 border">
                                <div
                                    onClick={() =>
                                        navigate("/dashboard/mi-cuenta")
                                    }
                                    className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom">
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2.5"
                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                        />
                                    </svg>
                                    <span className="text-sm">Mi perfil</span>
                                </div>
                                <hr className="my-2" />

                                <div
                                    onClick={() =>
                                        navigate(
                                            "/dashboard/cambiar-contraseña"
                                        )
                                    }
                                    className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom">
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                        />
                                    </svg>
                                    <span className="text-sm">
                                        Cambiar contraseña
                                    </span>
                                </div>
                                <hr className="my-2" />
                                <div
                                    onClick={() => {
                                        navigate("/");
                                        dispatch(logOut());
                                    }}
                                    className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom">
                                    <svg
                                        className="ml-0.5 w-6 h-6"
                                        stroke="#0060ff"
                                        fill="none">
                                        <g>
                                            <path d="M19.2854 12.0002L11.2727 12.0002"></path>
                                            <path d="M16.5101 15.6364L19.9999 12L16.5101 8.36363"></path>
                                            <path d="M13.4545 7V4H4V20H13.4545V17"></path>
                                        </g>
                                    </svg>
                                    <span className="absolute ml-8 text-sm">
                                        Cerrar sesión
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {prueba === "Si" && (
                    <div
                        onClick={() => {
                            window.open(
                                `/alojamientos/19030/casa-hacienda-cieneguilla`,
                                "_blank"
                            );
                        }}
                        className="fixed z-20 w-full text-xs  bg-blue-500 cursor-pointer xs:text-base  text-center sm:ml-48 lg:ml-[240px] ">
                        <p className="w-full mx-auto font-medium text-white sm:pr-60">
                            ¡Te queda {dias_restantes}{" "}
                            {dias_restantes > 1 ? "dias" : "dia"} de prueba
                            gratis!{" "}
                        </p>
                    </div>
                )}
            </div>

            {/* TOP BAR */}
            <div className="flex justify-center w-full mt-20 mb-10 sm:ml-48 lg:ml-64">
                {children}
            </div>
        </div>
    );
};
