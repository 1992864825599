import React, { useState, useEffect, useRef } from "react";
import { Navbar } from "../../dashboardPropietarios/Navbar";
import { NavbarMobile } from "../../dashboardPropietarios/NavbarMobile";
import { ReactSortable } from "react-sortablejs";
import { Sortable, SortableEvent, Store } from "react-sortablejs";

import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import BotonInformacion from "../../../components/helpers/BotonInformacion";

import Accordion from "./Accordion";

import { useAppSelector } from "../../../hooks/hooks";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";
import { ModalCrearServiciosAdicionales } from "./ModalCrearServiciosAdicionales";
import { ModalCrearServiciosAdicionalesConPlan } from "./ModalCrearServiciosAdicionalesConPlan";

export interface ItemInterface {
    id: number;
    id_cliente: number;
    tipo: string;
    id_chat: number;
    id_pedidos: number;
    proceso: string;
    chat: Chat;
    pedido: Pedido;
    motivo_perdida: string;
    usuario: Usuario;
    casa: Casa;
}

export interface Chat {
    id: number;
    chats_usuarios: ChatsUsuario[];
}

export interface ChatsUsuario {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    cliente: string;
    correo: string;
    usuario: Usuario;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

export interface Pedido {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    Cliente: Usuario;
}

export interface ServiciosSinPlan {
    id: number;
    titulo: string;
    descripcion: string;
    precio: string;
    habilitado: string;
    id_servicios_adicionales_tipo_plan: number;
    nombre_plan: string;
    tipo_pago: string;
}

export interface Estado {
    nombre: string;
    id: number;
}
export interface ServiciosConPlan {
    id: number;
    tipo_plan: string;
    servicios_adicionales: ServiciosAdicionale[];
}

export interface ServiciosAdicionale {
    id: number;
    titulo: string;
    descripcion: string;
    precio: string;
    tipo_pago: string;
}

export const ServiciosAdicionalesPagosAdmin = () => {
    const [showButtons, setShowButtons] = useState(false);
    const [serviciosSinPlan, setserviciosSinPlan] = useState<
        ServiciosSinPlan[]
    >([]);
    const [serviciosConPlan, setserviciosConPlan] = useState<
        ServiciosConPlan[]
    >([]);
    const [idPlan, setIdPlan] = useState(0);
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const [estado, setEstado] = useState("Primer seguimiento");

    const [estadoEdit, setEstadoEdit] = useState<Estado | null>(null);
    const [modalEliminar, setModalEliminar] = useState({
        visible: false,
        index: 0,
    });
    const [vista, setVista] = useState("linea");
    const [modalServiciosAdicionales, setModalServiciosAdicionales] =
        useState(false);

    const [
        modalServiciosAdicionalesConPlan,
        setModalServiciosAdicionalesConPlan,
    ] = useState(false);

    const getAll = async (cliente?: string) => {
        const data = await fetchAxiosToken({
            url: `/serviciosAdicionalesBlustay/getAll`,
            method: "get",
        });
        console.log(data);
        setserviciosSinPlan(data.serviciosSinPlan);
        setserviciosConPlan(data.serviciosConPlan);
    };

    useEffect(() => {
        getAll();
    }, [modalServiciosAdicionales, modalServiciosAdicionalesConPlan]);

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo",

            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await fetchAxiosToken({
                        url: `/serviciosAdicionalesBlustay/delete/${id}`,
                        method: "delete",
                    });
                    await getAll();
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Eliminado!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const eliminarPlan = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminar el plan?",

            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await fetchAxiosToken({
                        url: `/serviciosAdicionalesBlustay/deleteServiciosAdicionalesTipoPlan/${id}`,
                        method: "delete",
                    });
                    await getAll();
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Eliminado!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const crearPlan = () => {
        Swal.fire({
            title: "Crear nuevo plan",
            input: "text",
            inputAttributes: {
                autocapitalize: "Cancelar",
            },
            showCancelButton: true,
            confirmButtonText: "Crear",
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                console.log("login", login);
                try {
                    const { data } = await fetchAxiosToken({
                        url: `/serviciosAdicionalesBlustay/postServicioAdicionalTipoPlan`,
                        method: "post",
                        body: {
                            nombre: login,
                        },
                    });
                    await getAll();
                } catch (error) {
                    Swal.showValidationMessage(`
        Request failed: ${error}
      `);
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    // position: "top-end",
                    icon: "success",
                    title: "Creado!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        });
    };

    const render = () => {
        return (
            <div className="w-full mt-12">
                {modalServiciosAdicionales && (
                    <ModalCrearServiciosAdicionales
                        modalVisible={modalServiciosAdicionales}
                        setModalVisible={setModalServiciosAdicionales}
                    />
                )}
                {modalServiciosAdicionalesConPlan && idPlan > 0 && (
                    <ModalCrearServiciosAdicionalesConPlan
                        modalVisible={modalServiciosAdicionalesConPlan}
                        setModalVisible={setModalServiciosAdicionalesConPlan}
                        idPlan={idPlan}
                        setIdPlan={setIdPlan}
                    />
                )}
                <div className="flex flex-col items-center justify-end gap-2 px-3 mx-auto xs:flex-row lg:px-6">
                    <button
                        onClick={() => setModalServiciosAdicionales(true)}
                        className="flex items-center gap-1 p-2 border-2 rounded-lg h-[51px] border-[#CCCCCC] text-[#808080] hover:scale-105">
                        <svg
                            className="h-6 w-6 mt-0.5 text-[#808080]"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <span>Crear servicio</span>
                    </button>
                </div>
                <div>
                    <p className="mt-4 text-3xl font-semibold text-center text-[#029BFC] mb-10">
                        Servicios
                    </p>
                    <div className="flex flex-wrap justify-evenly">
                        {serviciosSinPlan.map((servicio) => {
                            return (
                                <div
                                    key={servicio.id}
                                    className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345] pb-6   w-[330px] mb-4 relative ">
                                    <button
                                        onClick={() =>
                                            handleEnable(servicio.id)
                                        }
                                        className={` absolute  dark:text-gray-400 top-2 right-2 ${"bg-slate-300 text-gray-800"}  rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 disabled:cursor-not-allowed z-50`}>
                                        <svg
                                            className="rounded-full hover:bg-gray-600 hover:text-white"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18 6L6 18"
                                                stroke="currentColor"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M6 6L18 18"
                                                stroke="currentColor"
                                                strokeWidth="1.66667"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                    <div className="flex flex-col gap-2 md:gap-0">
                                        <div className="flex flex-col ">
                                            <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                                {
                                                    <div className="flex">
                                                        <p className="text-base font-extrabold">
                                                            $
                                                        </p>
                                                        <p className="text-5xl text-[#179BD8] font-bold">
                                                            {" "}
                                                            {servicio.precio}
                                                        </p>
                                                    </div>
                                                }

                                                <div className="text-base font-semibold xl:text-lg">
                                                    /{servicio.tipo_pago}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col mt-6">
                                            <p className="font-bold">
                                                {servicio.titulo}
                                            </p>
                                            <p className="mt-3">
                                                {servicio.descripcion}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div>
                    <p className="mt-4 text-3xl font-semibold text-center text-[#029BFC]">
                        Planes
                    </p>
                </div>
                <div className="flex flex-col items-center justify-end gap-2 px-3 mx-auto xs:flex-row lg:px-6">
                    <button
                        onClick={() => crearPlan()}
                        className="flex items-center gap-1 p-2 border-2 rounded-lg h-[51px] border-[#CCCCCC] text-[#808080] hover:scale-105">
                        <svg
                            className="h-6 w-6 mt-0.5 text-[#808080]"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        <span>Crear plan</span>
                    </button>
                </div>
                {vista === "linea" && (
                    <Accordion
                        handleEnable={handleEnable}
                        setModalServiciosAdicionalesConPlan={
                            setModalServiciosAdicionalesConPlan
                        }
                        serviciosConPlan={serviciosConPlan}
                        eliminarPlan={eliminarPlan}
                        setIdPlan={setIdPlan}
                    />
                )}
            </div>
        );
    };
    return (
        <div>
            <NavbarAdministrativa titulo="Servicios adicionales">
                {render()}
                <NavbarAdministrativaMobile />
            </NavbarAdministrativa>
        </div>
    );
};
