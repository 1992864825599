import React, { useEffect, useState } from "react";
import axios from "axios";
// import SearchBar from "./SearchBar";
// import { Paginado } from "../helpers/Paginado";
import { useSelector } from "react-redux";
import ToggleButton from "../../../components/helpers/ToggleButton";
import Spinner from "../../../components/helpers/Spinner";
import { useAppSelector } from "../../../hooks/hooks";
import { NavbarBroker } from "../NavbarBroker";
import { NavbarMobileBroker } from "../NavbarMobileBroker";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import {
    ModalEditVariablePrice,
    Selected,
} from "../../dashboardPropietarios/calendario/ModalEditVariablePrice";
import { Paginado } from "../../../components/Paginado";

export interface DisponibilidadCasas {
    id: number;
    nombre: string;
    banner: string;
    terminado: string;
    precio_base: string;
    tipo_moneda: string;
    personas: number;
    clave: string;
    id_airbnb: string;
    id_booking: string;
    disponibilidades: Disponibilidades;
    pedidos: Pedidos;
    fechas_externas: FechasExternas;
    casa: Casa;
    precios_variables: VariablePrice[];
}

interface VariablePrice {
    id: number;
    id_casa: number;
    fecha: string;
    precio: string;
}
export interface Casa {
    precio_base: string;
    tipo_moneda: string;
}

export interface Disponibilidades {
    arrayDias: ArrayDia[];
    arrayFechas: ArrayFecha[];
}

export interface ArrayDia {
    dia: number;
    fecha_inicio: string;
    fecha_final: string;
}

export interface ArrayFecha {
    fecha: string;
    motivo: string;
}

export interface Pedidos {
    arrayFechasPedido: any[];
}

export interface FechasExternas {
    disponibilidadBC: DisponibilidadBc;
    disponibilidadAirBnb: DisponibilidadAirBnb;
    disponibilidadBooking: DisponibilidadBooking;
}

export interface DisponibilidadBc {
    arrayDias: any[];
    arrayMeses: any[];
    arrayFechas: string[];
    pedidos: any[];
}

export interface DisponibilidadAirBnb {
    arrayFechas: string[];
}

export interface DisponibilidadBooking {
    arrayFechas: string[];
}

const CalendarioBroker = () => {
    const [cargando, setCargando] = useState(true);
    const [nombre, setNombre] = useState("");
    const [total, setTotal] = useState(0);
    const [pagina, setpagina] = useState(1);
    const [page, setPage] = useState(new Date().getMonth() + 1);
    const [prev, setPrev] = useState(0);
    const [pageAño, setPageAño] = useState(new Date().getFullYear());
    const [mesArray, setMesArray] = useState<any[]>([]);
    const [modalVisiblePrice, setModalVisiblePrice] = useState<boolean>(false);
    const [idCasaSelect, setIdCasaSelect] = useState<number>(0);
    const [precioBaseSelect, setPrecioBaseSelect] = useState<string>("");
    const CASAS_PAGE = 8;
    const [variablePrice, setVariablePrice] = useState<VariablePrice[]>([]);

    const [selected, setSelected] = useState<Selected>({
        date: "",
        price: "",
        fechaNoDisponible: false,
    });
    const [disponibilidadCasas, setDisponibilidadCasas] = useState<
        DisponibilidadCasas[]
    >([]);
    const { id } = useAppSelector((state) => state.corredor);

    let hoy = new Date();

    let diasMes = new Date(pageAño, page, 0).getDate();
    let mesArrayBucle: number[] = [];
    let mesLetra = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];
    const [personas, setPersonas] = useState<number>(0);

    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const getAllDisponibilidades = async (
        page: number,
        search: string,
        personas: number
    ) => {
        setCargando(true);
        setDisponibilidadCasas([]);
        setTotal(0);
        const data = await fetchAxiosNoToken({
            url: `/corredor/getAllCasasGlobal/${id}?page=${page}&search=${search}&personas=${personas}`,
        });
        console.log("data", data);
        setTotal(data.totalItems);
        setDisponibilidadCasas(data.data);
        setTimeout(() => {
            setCargando(false);
        }, 1000);
    };

    const pagination = (pageNumber: number) => {
        setpagina(pageNumber);
        getAllDisponibilidades(pageNumber, nombre, personas);
    };

    useEffect(() => {
        getAllDisponibilidades(pagina, nombre, personas);
    }, [personas]);

    useEffect(() => {
        for (let i = 1; i < diasMes + 1; i++) {
            mesArrayBucle.push(i);
        }
        setMesArray(mesArrayBucle);
    }, [page]);

    const getPrecio = (
        variablePrice: VariablePrice[],
        precioBase: string,
        diaDelMes: number
    ) => {
        let diaPedido = `${pageAño}-${page < 10 ? `0${page}` : page}-${
            diaDelMes < 10 ? `0${diaDelMes}` : diaDelMes
        }`;

        let price = variablePrice.find((x) => x.fecha === diaPedido);

        return price ? price.precio : precioBase;
    };

    useEffect(() => {
        if (!modalVisiblePrice) {
            getAllDisponibilidades(pagina, nombre, personas);
            setSelected({
                date: "",
                price: "",
                fechaNoDisponible: false,
            });
            setPrecioBaseSelect("");
            setIdCasaSelect(0);
        }
    }, [modalVisiblePrice]);
    const colorDia = (
        disponibilidad: Disponibilidades,
        diaDelMes: number,
        pedidos: Pedidos,
        fechas_externas: FechasExternas
    ) => {
        let dias = disponibilidad.arrayDias;
        let fechas = disponibilidad.arrayFechas;
        let fechasAirBnb = fechas_externas.disponibilidadAirBnb.arrayFechas;
        let fechasBooking = fechas_externas.disponibilidadBooking.arrayFechas;
        let fechasBlueCorner = fechas_externas.disponibilidadBC.arrayFechas;
        let diasBlueCorner = fechas_externas.disponibilidadBC.arrayDias;
        let mesesBlueCorner = fechas_externas.disponibilidadBC.arrayMeses;

        let diaDate = new Date(`${page}/${diaDelMes}/${pageAño}`).getDay();
        let diaPedido = `${pageAño}-${page < 10 ? `0${page}` : page}-${
            diaDelMes < 10 ? `0${diaDelMes}` : diaDelMes
        }`;

        let diaDeSemanaEnRango = dias?.some((d) => {
            const fechaInicio = new Date(d.fecha_inicio);
            const fechaFinal = new Date(d.fecha_final);
            const fechaActual = new Date(
                `${page}/${
                    diaDelMes < 10 ? `0${diaDelMes}` : diaDelMes
                }/${pageAño}`
            );

            return (
                fechaActual >= fechaInicio &&
                fechaActual <= fechaFinal &&
                d.dia === diaDate
            );
        });

        if (
            dias.length === 0 &&
            fechas.length === 0 &&
            fechasAirBnb.length === 0 &&
            fechasBooking.length === 0 &&
            fechasBlueCorner.length === 0 &&
            diasBlueCorner.length === 0 &&
            mesesBlueCorner.length === 0 &&
            pedidos.arrayFechasPedido.length === 0
        ) {
            return "white";
        } else if (pedidos.arrayFechasPedido.indexOf(diaPedido) !== -1) {
            return "bg-[#c5c5c5] line-through";
        } else if (
            fechas.find(
                (fecha) =>
                    fecha.fecha.substring(0, 10) ===
                    `${pageAño}-${page < 10 ? `0${page}` : page}-${
                        diaDelMes < 10 ? `0${diaDelMes}` : diaDelMes
                    }`
            )
        ) {
            return "bg-[#c5c5c5] line-through";
        } else if (
            fechasAirBnb.find(
                (fecha) =>
                    fecha.substring(0, 10) ===
                    `${pageAño}-${page < 10 ? `0${page}` : page}-${
                        diaDelMes < 10 ? `0${diaDelMes}` : diaDelMes
                    }`
            )
        ) {
            return "bg-[#c5c5c5] line-through";
        } else if (
            fechasBooking.find(
                (fecha) =>
                    fecha.substring(0, 10) ===
                    `${pageAño}-${page < 10 ? `0${page}` : page}-${
                        diaDelMes < 10 ? `0${diaDelMes}` : diaDelMes
                    }`
            )
        ) {
            return "bg-[#c5c5c5] line-through";
        } else if (
            fechasBlueCorner.find(
                (fecha) =>
                    fecha.substring(0, 10) ===
                    `${pageAño}-${page < 10 ? `0${page}` : page}-${
                        diaDelMes < 10 ? `0${diaDelMes}` : diaDelMes
                    }`
            )
        ) {
            return "bg-[#c5c5c5] line-through";
        } else if (diasBlueCorner.find((dia) => dia === diaDelMes)) {
            return "bg-[#c5c5c5] line-through";
        } else if (mesesBlueCorner.find((mes) => mes + 1 === page)) {
            return "bg-[#c5c5c5] line-through";
        } else if (diaDeSemanaEnRango) {
            return "bg-[#c5c5c5] line-through";
        } else {
            return "white";
        }
    };

    const handleInputChange = (e: any) => {
        setNombre(e.target.value);

        setTimeout(() => {
            getAllDisponibilidades(pagina, e.target.value, personas);
        }, 500);
    };

    const modalPrecio = (casa: DisponibilidadCasas, dia: any) => {
        const precioBase = getPrecio(
            casa.precios_variables,
            casa.casa.precio_base,
            dia
        );
        let diaPedido = `${pageAño}-${page < 10 ? `0${page}` : page}-${
            dia < 10 ? `0${dia}` : dia
        }`;

        let fechaNoDisponible = casa.disponibilidades.arrayFechas.find(
            (x) => x.fecha === diaPedido
        );
        setIdCasaSelect(casa.id);
        setModalVisiblePrice(true);
        setPrecioBaseSelect(precioBase);
        setSelected({
            price: precioBase,
            date: diaPedido,
            fechaNoDisponible: fechaNoDisponible ? fechaNoDisponible : false,
        });
    };

    return (
        <NavbarBroker titulo="Calendario">
            {selected.price !== "" && (
                <ModalEditVariablePrice
                    idCasa={idCasaSelect || 0}
                    modalVisiblePrice={modalVisiblePrice}
                    setModalVisiblePrice={setModalVisiblePrice}
                    moneda={precioBaseSelect}
                    setVariablePrice={setVariablePrice}
                    selected={selected}
                />
            )}
            <div className="w-full">
                <div className="px-4 my-3">
                    <div className="mt-10">
                        <div
                            className={
                                "grid grid-cols-1 justify-items-center md:justify-items-start   w-[95%] mx-auto mt-4"
                            }>
                            <div className="flex items-center">
                                <div className="flex w-full p-1 mr-2 border-2 border-[#CCCCCC] rounded-lg appearance-none md:w-80 left-2 text-stone-900">
                                    <button className="flex items-center justify-center px-2">
                                        <svg
                                            className="2xl:w-6 2xl:h-6 w-5 h-5 text-[#808080]"
                                            fill="currentColor"
                                            viewBox="0 0 24 24">
                                            <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                                        </svg>
                                    </button>{" "}
                                    <input
                                        onBlur={() => setNombre("")}
                                        value={nombre}
                                        type="text"
                                        onChange={handleInputChange}
                                        className="w-full bg-transparent outline-none appearance-none 2xl:py-2 placeholder:text-[#808080]"
                                        placeholder="Buscar"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center mt-4">
                                <p className="mr-2">Personas</p>
                                <select
                                    value={personas}
                                    onChange={(e) =>
                                        setPersonas(Number(e.target.value))
                                    }
                                    className="pl-1 py-1 border-2 border-[#CCCCCC] rounded-lg ">
                                    <option value={"0"}>Seleccionar</option>
                                    {Array.from(
                                        { length: 100 },
                                        (_, index) => index + 1
                                    ).map((number) => (
                                        <option key={number} value={number}>
                                            {number}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-7 font-semibold text-lg lg:text-3xl md:text-2xl w-[95%] mx-auto py-2   bg-[#029BFC] text-white rounded-lg ">
                        {mesLetra[page - 1]}
                    </div>
                </div>
                {cargando ? (
                    <div className="flex flex-col items-center justify-center w-full h-full ">
                        <p className="mb-2 text-xl font-medium">
                            Espere por favor, estamos cargando sus datos
                        </p>
                        <Spinner width={20} />
                    </div>
                ) : (
                    <div>
                        <div>
                            <nav className="container flex justify-between  w-[95%] mx-auto select-none mb-2">
                                <div className="flex justify-between max-w-3xl pl-2 mt-8 cursor-pointer">
                                    <ul className="inline-flex ">
                                        <li
                                            onClick={() => {
                                                if (prev !== 0) {
                                                    if (page > 1) {
                                                        setPage(page - 1);
                                                        setPrev(prev - 1);
                                                    } else {
                                                        setPage(12);
                                                        setPageAño(pageAño - 1);
                                                        setPrev(prev - 1);
                                                    }
                                                }
                                            }}
                                            className="flex items-center hover:bg-slate-100">
                                            <svg
                                                className="h-8 w-8 p-0.5 text-[#029BFC] border border-[#029BFC] mx-1 rounded-lg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <polyline points="15 18 9 12 15 6" />
                                            </svg>
                                        </li>

                                        <li
                                            className={`bg-[#029BFC] text-white font-semibold border-[#029BFC] hover:border-gray-200 px-3 text-lg border rounded-lg 
                                               
                                        `}
                                            onClick={() => {
                                                setPage(
                                                    new Date().getMonth() + 1
                                                );
                                                setPageAño(
                                                    new Date().getFullYear()
                                                );
                                                setPrev(0);
                                            }}>
                                            <span>{"Hoy"} </span>
                                        </li>

                                        <li
                                            onClick={() => {
                                                if (page < 12) {
                                                    setPage(page + 1);
                                                    setPrev(prev + 1);
                                                } else {
                                                    setPage(1);
                                                    setPageAño(pageAño + 1);
                                                    setPrev(prev + 1);
                                                }
                                            }}
                                            className="flex items-center hover:bg-slate-100">
                                            <svg
                                                className="h-8 w-8 p-0.5 text-[#029BFC] border rounded-lg border-[#029BFC] mx-1"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <polyline points="9 18 15 12 9 6" />
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div
                            className={`container flex items-center ${
                                // total < 25
                                true ? "justify-center" : "justify-between"
                            }  w-[95%] mx-auto`}></div>
                        <div className=" w-[95%] mx-auto ">
                            <table className="w-full table-auto ">
                                <thead>
                                    <tr className="text-center ">
                                        <th className=" min-w-[150px]  text-sm font-bold border border-[#E9E9E9] py-2 px-10 bg-[#029BFC] text-white">
                                            {"Alojamientos"}
                                        </th>

                                        {mesArray?.map((dia, i) => (
                                            <th
                                                className={`text-sm font-normal text-[#3C3C3C] border-y border-r border-[#E9E9E9] p-2 ${
                                                    hoy.getDate() === dia &&
                                                    page ===
                                                        hoy.getMonth() + 1 &&
                                                    pageAño ===
                                                        hoy.getFullYear()
                                                        ? "bg-[#F7DC6F]"
                                                        : "bg-white"
                                                }`}
                                                key={i}>
                                                {dia < 10 ? `0${dia}` : dia}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                {
                                    <tbody>
                                        {disponibilidadCasas?.map((casa) => (
                                            <tr key={casa.id}>
                                                <td className="px-2 py-2 text-sm  border-b border-x border-[#E9E9E9] flex flex-col ">
                                                    {casa.nombre}
                                                    <span className="text-xs text-gray-400 ">
                                                        Personas max:{" "}
                                                        {casa.personas}
                                                    </span>
                                                </td>
                                                {mesArray?.map((dia, i) => (
                                                    <td
                                                        onClick={() => {
                                                            colorDia(
                                                                casa.disponibilidades,
                                                                dia,
                                                                casa.pedidos,
                                                                casa.fechas_externas
                                                            ) === "white" &&
                                                                modalPrecio(
                                                                    casa,
                                                                    dia
                                                                );
                                                        }}
                                                        className={`text-sm  border-y border-r p-1 border-[#E9E9E9]
                                                                 ${
                                                                     colorDia(
                                                                         casa.disponibilidades,
                                                                         dia,
                                                                         casa.pedidos,
                                                                         casa.fechas_externas
                                                                     ) ===
                                                                     "white"
                                                                         ? hoy.getDate() ===
                                                                               dia &&
                                                                           page ===
                                                                               hoy.getMonth() +
                                                                                   1 &&
                                                                           pageAño ===
                                                                               hoy.getFullYear()
                                                                             ? "bg-[#F7DC6F] cursor-pointer hover:scale-110 hover:bg-slate-200"
                                                                             : `bg-white cursor-pointer hover:scale-110 hover:bg-slate-200`
                                                                         : colorDia(
                                                                               casa.disponibilidades,
                                                                               dia,
                                                                               casa.pedidos,
                                                                               casa.fechas_externas
                                                                           )
                                                                 }
                                            }`}
                                                        key={i}>
                                                        {" "}
                                                        <p className="text-[8px] mx-auto text-center no-underline">
                                                            {
                                                                casa.casa
                                                                    .tipo_moneda
                                                            }
                                                        </p>
                                                        <p className="text-[10px]">
                                                            {getPrecio(
                                                                casa.precios_variables,
                                                                casa.casa
                                                                    .precio_base,
                                                                dia
                                                            )}
                                                        </p>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                }
                            </table>
                        </div>
                        <hr className="pl-4 mx-auto mt-12 border-white xs:hidden"></hr>
                        <div className="container flex items-center justify-between p-1 mx-auto">
                            <Paginado
                                elementsPerPage={CASAS_PAGE}
                                cantTotalElements={total}
                                pagination={pagination}
                                actualPage={pagina}
                            />
                        </div>
                    </div>
                )}
            </div>
            <NavbarMobileBroker />
        </NavbarBroker>
    );
};

export default CalendarioBroker;
