import React, { useState, useEffect } from "react";
import imagen2 from "../../img/landing_comprimidas/imagen-landing-2.webp";
import { useNavigate, useParams } from "react-router-dom";
import useCurrency from "../../hooks/useCurrency";
import { useAppSelector } from "../../hooks/hooks";

const Planes = () => {
    const navigate = useNavigate();
    const { currency } = useAppSelector((state) => state.informacion);
    const [moneda, setMoneda] = useState("");
    const { view, tags } = useParams();
    const [Plan, setPlan] = useState(false);

    const handlePlan = () => {
        setPlan(!Plan);
    };
    useEffect(() => {
        console.log("currency", currency);
        setMoneda(currency === "USD" ? "$" : "€");
    }, [currency]);

    return (
        <div className="bg-white ">
            <div className="flex flex-col justify-center ">
                <h2 className="bg-white py-5 text-[#179BD8]  text-center font-bold text-[25px] md:text-[30px] lg:text-[40px] mb-2">
                    Nuestros Planes
                </h2>
                <div className="">
                    <div className="flex items-center justify-center px-1 py-1 mx-auto border-2 w-60 rounded-3xl">
                        <div
                            className={`${
                                !Plan
                                    ? "bg-[#0E1928] text-[#C0D345]"
                                    : " text-[#0E1928]"
                            }   text-center  rounded-3xl cursor-pointer w-1/2 h-full `}
                            onClick={handlePlan}
                        >
                            <p className="text-lg ">Anual</p>
                            <p
                                className={`text-[8px] w-full  mb-1 ${
                                    !Plan ? "text-white" : " text-[#0E1928]"
                                }`}
                            >
                                Ahorra un 20% de dscto
                            </p>
                        </div>
                        <div
                            className={` ${
                                Plan
                                    ? "bg-[#0E1928] text-[#C0D345]"
                                    : " text-[#0E1928]"
                            }   px-4 text-lg   rounded-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                            onClick={handlePlan}
                        >
                            Mensual
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center w-full gap-6 px-5 mx-auto mt-5 lg:flex-row ">
                <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                    <div
                        className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                    >
                        Starter
                    </div>

                    <div className="flex flex-col gap-8 md:gap-0">
                        <div className="flex flex-col ">
                            <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                {Plan ? (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-4xl font-bold">
                                            {" "}
                                            {moneda === "$" ? 19 : 19}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-5xl text-[#179BD8] font-bold">
                                            {" "}
                                            {moneda === "$" ? 16 : 16}
                                        </p>
                                    </div>
                                )}

                                <div className="text-base font-semibold xl:text-lg">
                                    /mes
                                </div>
                            </div>
                            <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                {moneda}
                                {!Plan
                                    ? moneda === "$"
                                        ? "192"
                                        : "192"
                                    : moneda === "$"
                                    ? "228"
                                    : "228"}{" "}
                                al año.
                                {!Plan && (
                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                        Ahorras {moneda}
                                        {moneda === "$" ? 36 : 36}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col ">
                            <div
                                className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                            >
                                <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                    <li className="mb-1">
                                        Landing personalizada
                                    </li>
                                    <li className="mb-1">
                                        Dominio para tu página
                                    </li>
                                    <li className="mb-1">
                                        Reseñas de clientes
                                    </li>
                                    <li className="mb-1">
                                        Calendario de reservas
                                    </li>
                                    <li className="mb-1">
                                        Pago por transferencia
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario/1`)}
                            className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                        >
                            <p className="w-full text-center">COMPRAR</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col  rounded-2xl  bg-[#0E1928] text-white border-solid px-5   h-auto w-[330px] mb-4 ">
                    <div
                        className={` text-lg md:text-xl xl:text-2xl flex items-center justify-between w-full  ${"mt-5"} `}
                    >
                        <p>Estándar</p>{" "}
                        <p className="text-xs text-[#C0D345] bg-[#C0D345]/10 rounded-3xl px-3 py-1">
                            El más seleccionado
                        </p>
                    </div>

                    <div className="flex flex-col gap-8 md:gap-0">
                        <div className="flex flex-col ">
                            <div className="flex items-end mt-2 text-2xl md:text-3xl xl:text-5xl">
                                {Plan ? (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-4xl font-bold text-[#C0D345]">
                                            {" "}
                                            {moneda === "$" ? 39 : 39}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-5xl text-[#C0D345] font-bold">
                                            {" "}
                                            {moneda === "$" ? 32 : 32}
                                        </p>
                                    </div>
                                )}

                                <div className="text-base font-semibold xl:text-lg">
                                    /mes
                                </div>
                            </div>
                            <div className="flex items-center gap-1 mt-2 text-sm ">
                                {moneda}
                                {!Plan
                                    ? moneda === "$"
                                        ? "384"
                                        : "384"
                                    : moneda === "$"
                                    ? "468"
                                    : "468"}{" "}
                                al año.
                                {!Plan && (
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        Ahorras {moneda}
                                        {moneda === "$" ? 84 : 84}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col ">
                            <div className={` text-sm  mt-4 mb-0`}>
                                <ul className="ml-6 text-xs list-disc list-outside marker:text-white md:text-base">
                                    <li className="mb-1">CRM de clientes</li>
                                    <li className="mb-1">
                                        Plataformas de pago
                                    </li>
                                    <li className="mb-1">
                                        Creación de cupones
                                    </li>
                                    <li className="mb-1">
                                        Chat y gestión de clientes
                                    </li>
                                    <li className="mb-1">
                                        Resumen de estadísticas
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario/2`)}
                            className="bg-[#C0D345] py-1 px-8 my-8 text-base md:text-xl text-[#0E1928] font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                        >
                            <p className="w-full text-center">COMPRAR</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                    <div
                        className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                    >
                        Premium
                    </div>

                    <div className="flex flex-col gap-8 md:gap-0">
                        <div className="flex flex-col ">
                            <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                {Plan ? (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-4xl font-bold">
                                            {" "}
                                            {moneda === "$" ? 79 : 79}
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <p className="text-base font-bold">
                                            {moneda}
                                        </p>
                                        <p className="text-5xl text-[#179BD8] font-bold">
                                            {" "}
                                            {moneda === "$" ? 64 : 64}
                                        </p>
                                    </div>
                                )}

                                <div className="text-base font-semibold xl:text-lg">
                                    /mes
                                </div>
                            </div>
                            <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                {moneda}
                                {!Plan
                                    ? moneda === "$"
                                        ? "768"
                                        : "768"
                                    : moneda === "$"
                                    ? "948"
                                    : "948"}{" "}
                                al año.
                                {!Plan && (
                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                        Ahorras {moneda}
                                        {moneda === "$" ? 180 : 180}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col ">
                            <div
                                className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                            >
                                <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                    <li className="mb-1">Chatbot interno</li>
                                    <li className="mb-1">
                                        Integrar google calendar
                                    </li>
                                    <li className="mb-1">
                                        Plantillas para contratos por Alquiler
                                    </li>
                                    <li className="mb-1">+2 Subusuarios</li>
                                    <li className="mb-1">
                                        Integrar Airbnb y Booking
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario/3`)}
                            className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                        >
                            <p className="w-full text-center">COMPRAR</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center ">
                <button
                    onClick={() =>
                        navigate(`/home/${tags ? tags : "6"}/planes`)
                    }
                    className=" mb-4  bg-[#C0D345] rounded-3xl py-3 md:py-3 px-6 md:px-8 text-[#0E1928] gap-2 text-lg flex items-center"
                >
                    <p>Conocer más</p>
                    <div className="px-1 ">
                        <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_1_38)">
                                <path
                                    d="M5.81305 4.99428L0.170438 0.765716C0.0751903 0.694486 0.0139897 0.590648 0.000291429 0.477054C-0.0134069 0.363461 0.0215221 0.249419 0.097397 0.160003C0.173272 0.0705869 0.283873 0.0131247 0.404875 0.000265013C0.525877 -0.0125946 0.647366 0.0202045 0.742613 0.0914341L6.82957 4.66286C6.88203 4.70416 6.92421 4.75574 6.95316 4.81399C6.9821 4.87223 6.9971 4.93573 6.9971 5C6.9971 5.06428 6.9821 5.12777 6.95316 5.18602C6.92421 5.24427 6.88203 5.29585 6.82957 5.33714L0.742613 9.90857C0.647366 9.9798 0.525877 10.0126 0.404875 9.99974C0.283873 9.98688 0.173272 9.92942 0.097397 9.84C0.0215221 9.75059 -0.0134069 9.63655 0.000291429 9.52295C0.0139897 9.40936 0.0751903 9.30552 0.170438 9.23429L5.81305 4.99428Z"
                                    fill="#0E1928"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_38">
                                    <rect width="7" height="10" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    );
};
export default Planes;
