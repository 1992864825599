import React from "react";

import { Helmet } from "react-helmet";
import MainNavbar from "../MainNavbar/MainNavbar";
import { Footer } from "../Footer";

const PoliticasDeUsoYPriv = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <MainNavbar />

            <div className="bg-[#0E1928] pt-32 h-72 flex">
                <p className="w-10/12 m-auto text-2xl font-bold text-center text-white ">
                    Política de Privacidad y Tratamiento de Datos Personales
                </p>
            </div>
            <div className="w-10/12 mx-auto mt-10 text-lg text-[#0E1928] mb-10">
                <p>
                    En BluStay, estamos comprometidos con la protección y el
                    respeto de tu privacidad. Esta política de tratamiento de
                    datos explica cómo recopilamos, usamos, compartimos,
                    almacenamos y protegemos tus datos personales cuando
                    interactúas con nuestra página web, plataformas
                    publicitarias y servicios. Cumplimos con todas las
                    regulaciones de privacidad y protección de datos aplicables,
                    incluidas el Reglamento General de Protección de Datos
                    (GDPR) de la Unión Europea, la Ley de Privacidad del
                    Consumidor de California (CCPA) y otras normativas globales.
                </p>
                <div>
                    <ol className="mt-10">
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                1. Qué Datos Recopilamos
                            </p>

                            <p className="text-lg font-bold mt-5">
                                1.1. Datos Personales
                            </p>
                            <ul className="list-disc mt-5">
                                <li className="ml-10">
                                    <b>Identificación básica:</b> nombre,
                                    apellido, dirección de correo electrónico,
                                    número de teléfono, fecha de nacimiento.
                                </li>
                                <li className="ml-10">
                                    <b>Información financiera:</b> datos de la
                                    tarjeta de crédito o débito, cuenta bancaria
                                    u otros métodos de pago, que se utilizan
                                    exclusivamente para procesar transacciones.
                                    Estos datos están <b>encriptados</b> y
                                    protegidos de acuerdo con los estándares
                                    internacionales de seguridad, y el cifrado
                                    SSL es verificado por nuestro certificado de
                                    seguridad con el identificador{" "}
                                    <b>
                                        c446abb8839b5088da09a7ab275ef01a667802a6dcd1a1d4d278b96bf219b07a.
                                    </b>
                                </li>
                                <li className="ml-10">
                                    <b>Datos de cuenta:</b> cuando creas una
                                    cuenta en nuestra plataforma, almacenamos
                                    información como el nombre de usuario,
                                    contraseña y preferencias de configuración.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                1.2. Datos de Navegación
                            </p>
                            <ul className="list-disc mt-5">
                                <li className="ml-10">
                                    <b>Dirección IP:</b> se registra
                                    automáticamente cuando accedes a nuestro
                                    sitio web, permitiéndonos identificar tu
                                    ubicación aproximada (ciudad o país) para
                                    personalizar tu experiencia.
                                </li>
                                <li className="ml-10">
                                    <b>Historial de navegación:</b> recopilamos
                                    información sobre las páginas que visitas,
                                    el tiempo que pasas en ellas y las acciones
                                    que realizas, como clics en enlaces o
                                    descargas de archivos.
                                </li>
                                <li className="ml-10">
                                    <b>Dispositivos:</b> tipo de dispositivo
                                    utilizado para acceder a nuestros servicios
                                    (móvil, tablet, ordenador), sistema
                                    operativo y tipo de navegador.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                1.3. Información sobre Preferencias
                            </p>
                            <ul className="list-disc mt-5">
                                <li className="ml-10">
                                    <b>Intereses y preferencias:</b> recopilamos
                                    datos sobre tus preferencias y
                                    comportamientos de usuario, como propiedades
                                    que te interesan o búsquedas que realizas,
                                    para personalizar nuestras recomendaciones y
                                    promociones.
                                </li>
                            </ul>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                1.4. Datos de Redes Sociales
                            </p>
                            <ul className="list-disc mt-5">
                                <li className="ml-10">
                                    Si decides conectarte a nuestros servicios a
                                    través de una cuenta de red social, como
                                    Facebook o Google, podemos recopilar
                                    información pública de tu perfil y otra
                                    información que la plataforma de redes
                                    sociales comparta con nosotros, de acuerdo
                                    con sus políticas de privacidad.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                2. Finalidades del Tratamiento de Datos
                            </p>
                            <p className="text-lg mt-5">
                                Tratamos tus datos personales para múltiples
                                propósitos, garantizando siempre la legalidad y
                                transparencia en cada actividad de tratamiento:
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                2.1. Provisión y Mejora de Servicios
                            </p>
                            <p className="text-lg mt-5">
                                Utilizamos tus datos para ofrecerte acceso a
                                nuestra plataforma, gestionar reservas de
                                propiedades, y brindarte una experiencia
                                personalizada. Esto incluye:
                            </p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    Procesar tus reservas y transacciones
                                    financieras.
                                </li>
                                <li className="ml-10">
                                    Gestionar tus solicitudes de información o
                                    soporte técnico.
                                </li>
                                <li className="ml-10">
                                    Mejorar la calidad y el funcionamiento de
                                    nuestro sitio web y aplicaciones.
                                </li>
                                <li className="ml-10">
                                    Adaptar el contenido a tus preferencias
                                    mediante análisis de datos y personalización
                                    de la interfaz de usuario.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                2.2. Marketing y Publicidad Personalizada
                            </p>
                            <ul className="list-disc mt-5">
                                <li className="ml-10">
                                    Con tu consentimiento, usamos tus datos para
                                    enviarte comunicaciones promocionales sobre
                                    nuestras propiedades y ofertas especiales.
                                    Esto incluye el uso de tus datos para
                                    mostrarte anuncios personalizados en
                                    plataformas como Google Ads, Facebook y
                                    otras redes sociales.
                                </li>
                                <li className="ml-10">
                                    Podemos combinar datos de navegación con
                                    otra información que tengamos sobre ti para
                                    crear perfiles de usuario que nos permitan
                                    enviarte ofertas relevantes.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                2.3. Cumplimiento Legal y Obligaciones
                                Regulatorias
                            </p>
                            <p className="text-lg mt-5">
                                Estamos obligados a cumplir con varias leyes y
                                normativas que requieren la recopilación y el
                                almacenamiento de ciertos datos personales, por
                                ejemplo:
                            </p>
                            <ul className="list-disc mt-5">
                                <li className="ml-10">
                                    Mantener registros precisos de
                                    transacciones.
                                </li>
                                <li className="ml-10">
                                    Proporcionar información a las autoridades
                                    cuando así lo requieran legalmente.
                                </li>
                                <li className="ml-10">
                                    Cumplir con leyes internacionales de
                                    protección de datos, incluyendo solicitudes
                                    de acceso o eliminación de datos.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                2.4. Mejora del Servicio y Análisis Interno
                            </p>
                            <p className="text-lg mt-5">
                                Realizamos análisis de datos para entender mejor
                                cómo los usuarios interactúan con nuestros
                                servicios y cómo podemos mejorar. Esto incluye:
                            </p>
                            <ul className="list-disc">
                                <li className="ml-10">
                                    Análisis de tendencias generales de
                                    comportamiento en nuestra plataforma.
                                </li>
                                <li className="ml-10">
                                    Desarrollo de nuevas funcionalidades y
                                    mejoras del sitio web o aplicaciones
                                    móviles.
                                </li>
                            </ul>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                3. Base Legal para el Tratamiento de Datos
                            </p>

                            <p className="text-lg mt-5">
                                Cumplimos con la normativa internacional,
                                incluida la normativa del GDPR y CCPA, al
                                garantizar que tus datos sean tratados sobre una
                                base legal clara:
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                3.1 Consentimiento
                            </p>

                            <p className="text-lg mt-5">
                                Solicitamos tu consentimiento explícito antes de
                                recopilar o procesar ciertos tipos de datos,
                                especialmente aquellos relacionados con el
                                marketing personalizado y las cookies.
                            </p>
                        </li>
                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                3.2. Cumplimiento de un Contrato
                            </p>

                            <p className="text-lg mt-5">
                                Procesamos tus datos para cumplir con el
                                contrato entre tú y BluStay cuando haces una
                                reserva o te registras en nuestra plataforma.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                3.3. Obligaciones Legales
                            </p>

                            <p className="text-lg mt-5">
                                Tratamos tus datos cuando es necesario para
                                cumplir con nuestras obligaciones legales, como
                                mantener registros fiscales o responder a las
                                solicitudes de las autoridades competentes.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                4. Cómo Compartimos los Datos
                            </p>

                            <p className="text-lg mt-5">
                                Nos comprometemos a no vender, alquilar ni
                                intercambiar tus datos personales con terceros
                                para fines comerciales, tales como bases de
                                datos de marketing. Solo compartimos tus datos
                                personales con terceros en los siguientes casos:
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                4.1. Proveedores de Servicios
                            </p>

                            <p className="text-lg mt-5">
                                Trabajamos con terceros que nos ayudan a brindar
                                nuestros servicios, incluidos:
                            </p>

                            <ul className="list-disc">
                                <li className="ml-10">
                                    Proveedores de servicios de pago que
                                    procesan transacciones de forma segura.
                                </li>
                                <li className="ml-10">
                                    Plataformas de alojamiento web y análisis de
                                    datos que facilitan el funcionamiento y la
                                    mejora de nuestro sitio web.
                                </li>
                            </ul>

                            <p className="text-lg mt-5">
                                Estos terceros tienen una{" "}
                                <b>relación directa</b>
                                con nuestra organización y están sujetos a
                                estrictas obligaciones contractuales de
                                confidencialidad y protección de datos.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                4.2. Plataformas Publicitarias
                            </p>

                            <p className="text-lg mt-5">
                                Para ofrecerte anuncios personalizados,
                                compartimos ciertos datos de navegación con
                                plataformas publicitarias como Google Ads y Meta
                                (Facebook, Instagram). Estas plataformas pueden
                                usar la información para mostrarte anuncios
                                relevantes basados en tus interacciones previas
                                con nuestro sitio web.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                4.3. Cumplimiento Legal
                            </p>

                            <p className="text-lg mt-5">
                                Compartimos datos personales con las autoridades
                                gubernamentales y regulatorias cuando sea
                                necesario para cumplir con la ley o para
                                proteger nuestros derechos legales.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                5. Seguridad de los Datos
                            </p>

                            <p className="text-lg mt-5">
                                En BluStay, tomamos en serio la seguridad de tus
                                datos personales y utilizamos varias medidas
                                para protegerlos contra accesos no autorizados,
                                alteración, pérdida o divulgación indebida.
                                Entre las medidas que aplicamos se encuentran:
                            </p>

                            <ul className="list-disc">
                                <li className="ml-10">
                                    <b>Encriptación de datos:</b> todos los
                                    datos sensibles, como información
                                    financiera, se almacenan y transmiten
                                    mediante protocolos de encriptación
                                    avanzados. El cifrado SSL es garantizado por
                                    nuestros certificados de seguridad,
                                    incluyendo el identificado por el código{" "}
                                    <b>
                                        c446abb8839b5088da09a7ab275ef01a667802a6dcd1a1d4d278b96bf219b07a.
                                    </b>
                                </li>
                                <li className="ml-10">
                                    <b>Control de acceso:</b> restringimos el
                                    acceso a los datos personales únicamente a
                                    los empleados y proveedores de servicios que
                                    necesiten dicha información para cumplir con
                                    sus responsabilidades.
                                </li>

                                <li className="ml-10">
                                    <b>Auditorías de seguridad: </b>
                                    realizamos evaluaciones de seguridad
                                    periódicas para identificar posibles
                                    vulnerabilidades y mejorarlas.
                                </li>
                            </ul>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                6. Retención de Datos
                            </p>

                            <p className="text-lg mt-5">
                                Conservamos tus datos personales durante el
                                tiempo necesario para cumplir con los fines
                                descritos en esta política, a menos que la ley
                                nos exija o permita un período de retención más
                                prolongado. Algunos criterios que determinan los
                                períodos de retención incluyen:
                            </p>

                            <ul className="list-disc">
                                <li className="ml-10">
                                    La duración de tu relación con nosotros como
                                    cliente.
                                </li>
                                <li className="ml-10">
                                    La necesidad de cumplir con obligaciones
                                    legales o regulatorias.
                                </li>

                                <li className="ml-10">
                                    El tiempo necesario para resolver disputas o
                                    hacer cumplir acuerdos legales.
                                </li>
                            </ul>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">7. Tus Derechos</p>

                            <p className="text-lg mt-5">
                                Bajo las leyes de protección de datos, tienes
                                una serie de derechos sobre tus datos
                                personales:
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                7.1. Derecho de Acceso
                            </p>

                            <p className="text-lg mt-5">
                                Puedes solicitar una copia de los datos
                                personales que tenemos sobre ti y conocer cómo
                                los utilizamos.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                7.2. Derecho de Rectificación
                            </p>

                            <p className="text-lg mt-5">
                                Si crees que tus datos personales son
                                incorrectos o están incompletos, puedes
                                solicitar que los corrijamos o completemos.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                7.3. Derecho de Eliminación
                            </p>

                            <p className="text-lg mt-5">
                                En ciertas circunstancias, puedes solicitar que
                                eliminemos tus datos personales, por ejemplo, si
                                ya no son necesarios para los fines para los que
                                fueron recopilados.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                7.4. Derecho a la Portabilidad de Datos
                            </p>

                            <p className="text-lg mt-5">
                                Tienes derecho a recibir tus datos en un formato
                                estructurado, de uso común y lectura mecánica, y
                                a solicitar que transfiramos esos datos a otra
                                entidad cuando sea técnicamente viable.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                7.5. Derecho a Oponerte al Tratamiento
                            </p>

                            <p className="text-lg mt-5">
                                Puedes oponerte al tratamiento de tus datos en
                                cualquier momento, especialmente en relación con
                                el marketing directo.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                8. Cookies y Tecnologías Similares
                            </p>

                            <p className="text-lg mt-5">
                                Utilizamos cookies y tecnologías similares para
                                ofrecerte una experiencia de usuario
                                personalizada, analizar patrones de tráfico y
                                mejorar la seguridad de nuestro sitio. Puedes
                                administrar tus preferencias de cookies a través
                                de la configuración de tu navegador o nuestras
                                opciones de preferencias de cookies en el sitio
                                web.
                            </p>
                        </li>

                        <li className="mt-10 ml-5">
                            <p className="text-lg font-bold">
                                9. Actualización de la Política
                            </p>

                            <p className="text-lg mt-5">
                                Podemos actualizar esta política de tratamiento
                                de datos en cualquier momento. Cualquier cambio
                                importante te será notificado de manera visible
                                en nuestra página web o a través de una
                                comunicación directa.
                            </p>
                        </li>
                    </ol>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PoliticasDeUsoYPriv;
