import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import Spinner from "../../../components/helpers/Spinner";

interface Props {
    setModalVisible: (modalVisible: boolean) => void;
    modalVisible: boolean;
}

interface comfort {
    id: number;
    nombre: string;
    icono: string;
    habilitado: string;
    fecha_registro: string;
}

export const ModalCrearServiciosAdicionales = ({
    setModalVisible,
    modalVisible,
}: Props) => {
    const [saving, setSaving] = useState<boolean>(false);

    const [input, setInput] = useState({
        descripcion: "",
        titulo: "",
        precio: 0,
        tipo_pago: "Mensual",
    });

    const handleSubmit = async () => {
        setSaving(true);

        try {
            const data = await fetchAxiosToken({
                url: `/serviciosAdicionalesBlustay/`,
                method: "post",
                body: {
                    titulo: input.titulo,
                    descripcion: input.descripcion,

                    precio: input.precio,
                    id_servicios_adicionales_tipo_plan: 0,
                    nombre_plan: "",
                    tipo_pago: input.tipo_pago,
                },
            });

            try {
                setTimeout(() => {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Creado",
                        text: "Servicio creado correctamente",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                    setSaving(false);
                    setModalVisible(false);
                }, 2000);
            } catch (error: any) {
                console.log(error.response);
            }
        } catch (error: any) {
            console.log(error.response);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Nuevo servicio</h2>
                <hr className="w-full bg-gray-300" />

                <div className="w-full mt-3 text-left">
                    <header className="2xl:mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                        Titulo:
                    </header>
                </div>
                <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                    <input
                        onChange={(e) =>
                            setInput({
                                ...input,
                                titulo: e.target.value,
                            })
                        }
                        type="text"
                        value={input.titulo || ""}
                        placeholder="Titulo"
                        className="w-full 2xl:my-3 my-2.5 2xl:text-base text-sm bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                    />
                </div>
                <div className="w-full mt-1 text-left 2xl:mt-3">
                    <header className="2xl:mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                        Descripcion:
                    </header>
                </div>
                <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                    <textarea
                        onChange={(e) =>
                            setInput({
                                ...input,
                                descripcion: e.target.value,
                            })
                        }
                        value={input.descripcion || ""}
                        rows={4}
                        placeholder="Descripcion"
                        className="w-full 2xl:my-3 my-2.5 2xl:text-base text-sm bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                    />
                </div>

                <div className="w-full mt-3 text-left">
                    <header className="2xl:mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                        Precio:
                    </header>
                </div>
                <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                    <input
                        onChange={(e) =>
                            setInput({
                                ...input,
                                precio: Number(e.target.value),
                            })
                        }
                        type="number"
                        value={input.precio > 0 ? input.precio : ""}
                        placeholder="Precio"
                        className="w-full 2xl:my-3 my-2.5 2xl:text-base text-sm bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                    />
                </div>
                <div className="w-full mt-3 text-left">
                    <header className="2xl:mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                        Tipo de pago:
                    </header>
                </div>
                <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                    <select
                        name="tipo_pago"
                        value={input.tipo_pago}
                        onChange={(e) =>
                            setInput({
                                ...input,
                                tipo_pago: e.target.value,
                            })
                        }
                        className="w-full py-3 text-sm text-gray-400 bg-transparent border border-none rounded-lg outline-none placeholder:italic 2xl:text-base focus:outline-none">
                        <option value="Mensual">Mensual</option>
                        <option value="Único">Único</option>
                    </select>
                    {/* <input
                        onChange={(e) =>
                            setInput({
                                ...input,
                                precio: Number(e.target.value),
                            })
                        }
                        type="number"
                        value={input.precio > 0 ? input.precio : ""}
                        placeholder="Precio"
                        className="w-full 2xl:my-3 my-2.5 2xl:text-base text-sm bg-transparent border-none outline-none focus:outline-none placeholder:text-gray-400"
                    /> */}
                </div>

                <div className="flex flex-col gap-4 mt-2 2xl:mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105  2xl:text-base text-sm">
                            Crear
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
