import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { ButtonSiguiente } from "./ButtonSiguiente";
import nombreAlojamiento from "../../img/onboarding/nombra_alojamiento.jpg";

import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { set_landing_house } from "../../redux/reducers/landingReducer";
export const NombreCasa = () => {
    const {
        nombre,
        id: id_casa,
        plan,
    } = useAppSelector((state) => state.landing_house.house);
    const dispatch = useAppDispatch();
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const [input, setInput] = useState({
        nombre: nombre,
    });
    const { id: id_usuario } = useAppSelector((state) => state.usuario_logeado);

    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        setInput({
            nombre: nombre,
        });
    }, [nombre]);

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);
    console.log("id_casa", id_casa);
    const handleSubmit = async () => {
        if (!input.nombre)
            return Swal.fire({
                title: "Debes escoger un nombre válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
            });
        try {
            if (idCasa && Number(idCasa) > 0) {
                await fetchAxiosToken({
                    url: `/casa/putNombre/${idCasa}`,
                    method: "put",
                    body: {
                        nombre: input.nombre,
                    },
                });

                await fetchAxiosToken({
                    url: `/casa/putPagina/${idCasa}`,
                    method: "put",
                    body: {
                        pagina: "capacidad",
                    },
                });
                console.log("casa actualizada");
                dispatchDominio();
                navigate(`/dashboard-corredor/onboarding/capacidad/${idCasa}`);
            } else if (id_casa) {
                await fetchAxiosToken({
                    url: `/casa/putNombre/${id_casa}`,
                    method: "put",
                    body: {
                        nombre: input.nombre,
                    },
                });

                await fetchAxiosToken({
                    url: `/casa/putPagina/${id_casa}`,
                    method: "put",
                    body: {
                        pagina: "capacidad",
                    },
                });
                dispatchDominio();

                if (plan > 0) {
                    navigate(`/dashboard/onboarding/capacidad`);
                } else {
                    navigate(`/dashboard/onboarding/suscripcion`);
                }
            } else {
                const data = await fetchAxiosToken({
                    url: `/casa`,
                    method: "post",
                    body: {
                        id_propietario: id_usuario,
                        nombre: input.nombre,
                    },
                });

                await fetchAxiosToken({
                    url: `/casa/putPagina/${data.data.id}`,
                    method: "put",
                    body: {
                        pagina: "capacidad",
                    },
                });
                console.log("data", data.data);
                console.log("");
                !idCasa && dispatchDominio();
                navigate(
                    idCasa
                        ? `/dashboard-corredor/onboarding/capacidad/${data.data.id}`
                        : plan > 0
                        ? `/dashboard/onboarding/capacidad`
                        : `/dashboard/onboarding/suscripcion`
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/3 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={nombreAlojamiento}
                        alt="nombreAlojamiento"
                        className="w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Escoge un nombre para tu alojamiento
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center h-screen p-3 text-center text-black bg-white">
                <ButtonRegresar
                    url={`${
                        idCasa
                            ? "/dashboard-corredor/casas"
                            : "/dashboard/onboarding/bienvenida"
                    }`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col items-start justify-center w-3/4 mx-auto md:w-2/3 lg:w-1/2">
                    <h1 className="text-2xl font-medium md:text-3xl lg:text-4xl">
                        Nombre
                    </h1>
                    <input
                        type={"text"}
                        name="nombre"
                        value={input.nombre}
                        onChange={handleInputChange}
                        placeholder="ej. casa rosales"
                        className="w-full p-3 mt-4 text-black border border-gray-500 rounded-lg placeholder:italic"
                    />

                    <ButtonSiguiente onSubmit={() => handleSubmit()} />
                </div>
            </div>
        </div>
    );
};
